import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { IStateTree } from 'typings/store';

import { Home, Join, NotFound, Faq } from '@app/pages';

import { APP_URL_LIST } from './urls';

export default function RootRouteer() {
  const { data } = useSelector(({ session }: IStateTree) => session, shallowEqual);

  /**
   * Use effect that sets logged in user to sentry for session tracking
   */
  useEffect(() => {
    if (data?.access_token) {
      // Sentry.setUser({ ...user });
    }
  }, [data]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={APP_URL_LIST.HOME_URL} element={<Home />} />

        <Route path={APP_URL_LIST.JOIN} element={<Join />} />
        <Route path={APP_URL_LIST.FAQ} element={<Faq />} />

        <Route path={APP_URL_LIST.NOT_FOUND_URL} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
