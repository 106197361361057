import { FunctionComponent } from 'react';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { RawIntlProvider, IntlShape, createIntl, createIntlCache } from 'react-intl';

import { IStateTree } from 'typings/store';
import { Locale } from '@app/providers/store/settings/models';

import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/es';
import 'intl/locale-data/jsonp/de';
import 'intl/locale-data/jsonp/it';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/it';

import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/es';
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/it';

import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/en-gb';

import en from './locales/en.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import de from './locales/de.json';
import it from './locales/it.json';

export let translator = {} as IntlShape;

const cache = createIntlCache();

const messages: Record<Locale, {}> = { en, fr, es, de, it };

const Provider: FunctionComponent<{}> = ({ children }) => {
  const { locale } = useSelector(({ settings }: IStateTree) => settings, shallowEqual);
  const parsedLocale = locale === 'en' ? 'en-gb' : locale;
  moment.locale(parsedLocale);

  translator = createIntl(
    { locale: parsedLocale, defaultLocale: parsedLocale, messages: messages[locale] },
    cache
  );

  return <RawIntlProvider value={translator}>{children}</RawIntlProvider>;
};

export default Provider;
