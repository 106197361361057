import styled from 'styled-components';

import { Button } from '@app/components/atoms';
import { BottomCurve } from '@app/pages/join/Join.styles';

export const Container = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const BackgroundCurve = styled(BottomCurve)`
  display: block;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  max-width: 23rem;
  text-align: center;
  line-height: 3.8rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_PIXEL_XL}) {
    max-width: max-content;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.SMALL_VIEWPORT}) {
    font-size: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 5rem;
    line-height: 3.8vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    font-size: 7.5rem;
  }
`;

export const SubTitle = styled.p`
  opacity: 0.8;
  margin-top: 3vw;
  max-width: 27rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 3rem;

  br {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 1.3rem;
    line-height: 2.1rem;
    max-width: max-content;

    br {
      display: inline-block;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT_IPAD_PRO}) {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    margin-top: 2vw;
  }
`;

export const GoBackButton = styled(Button)`
  padding: 0 4rem;
  width: fit-content;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    padding: 0 5rem;
    margin-top: 1rem;

    div {
      font-size: 1.2rem;
    }
  }
`;
