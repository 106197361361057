import { FormattedMessage } from 'react-intl';
import { Icon } from '@app/components/atoms';

import messages from './messages';
import { Container, LogoContainer, FooterList, AchorTag } from './Footer.styles';

export const Footer = () => {
  return (
    <Container>
      <div className="footer">
        <div className="footer--address">
          <LogoContainer>
            <Icon name="logo" color="MEDIUMSLATEBLUE_COLOR" className="logo--icon" />
            <p className="logo--text">evarfinance</p>
          </LogoContainer>

          <a href="mailto:hello@evarfinance.com" className="mail-text">
            hello@evarfinance.com
          </a>

          <ul>
            <a
              target="_blank"
              className="link-text dot"
              rel="noopener noreferrer"
              href="https://blog.evarfinance.com/terms-of-use"
            >
              <FormattedMessage {...messages.terms_of_use} />
            </a>

            <a
              target="_blank"
              className="link-text"
              rel="noopener noreferrer"
              href="https://blog.evarfinance.com/privacy-policy"
            >
              <FormattedMessage {...messages.privacy_policy} />
            </a>
          </ul>

          <p>
            Evarfinance Inc. &copy; {new Date().getFullYear()}.{' '}
            <FormattedMessage {...messages.rights_reserved} />
          </p>
        </div>

        <FooterList>
          <li>
            <AchorTag
              href="https://www.linkedin.com/company/evarfinance/"
              aria-label="Visit our linkedin page"
            >
              <Icon name="linkedin" />
            </AchorTag>
          </li>
          <li>
            <AchorTag href="https://twitter.com/evarfinance" aria-label="Visit our twitter page">
              <Icon name="twitter" />
            </AchorTag>
          </li>
          <li>
            <AchorTag
              href="https://www.instagram.com/evarfinance/"
              aria-label="Visit our instagram page"
            >
              <Icon name="instagram" />
            </AchorTag>
          </li>
          <li>
            <AchorTag href="https://t.me/evarfinance" aria-label="Visit our telegram page">
              <Icon name="telegram" />
            </AchorTag>
          </li>
        </FooterList>
      </div>
    </Container>
  );
};
