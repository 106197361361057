export enum IColorMode {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum SwitchScreen {
  MAIN_SCREEN = 'MAIN_SCREEN',
  AUTH_SCREEN = 'AUTH_SCREEN',
}

export enum Locale {
  FRENCH = 'fr',
  GERMAN = 'de',
  ENGLISH = 'en',
  SPANISH = 'es',
  ITALIAN = 'it',
}

export interface ISettingsState {
  locale: Locale;
  screen: SwitchScreen;
  colorMode: IColorMode;
}
