import { Icon } from '../Icon/Icon';
import { ButtonProps } from './Button.interface';
import { Content, PrimaryButton, SecondaryButton, TertiaryButton } from './Button.styles';

export const Button = ({
  onClick,
  children,
  isLoading = false,
  variant = 'primary',
  ...props
}: ButtonProps) => {
  switch (variant) {
    case 'primary': {
      return (
        <PrimaryButton onClick={onClick} {...(props as any)} disabled={isLoading}>
          <Content>{isLoading ? <Icon name="loading" /> : children}</Content>
        </PrimaryButton>
      );
    }

    case 'secondary': {
      return (
        <SecondaryButton onClick={onClick} {...(props as any)} disabled={isLoading}>
          <Content>{isLoading ? <Icon name="loading" /> : children}</Content>
        </SecondaryButton>
      );
    }

    case 'tertiary': {
      return (
        <TertiaryButton onClick={onClick} {...(props as any)} disabled={isLoading}>
          <Content>{isLoading ? <Icon name="loading" /> : children}</Content>
        </TertiaryButton>
      );
    }
  }
};
