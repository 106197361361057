import { network } from '@app/config';
import { ISessionUser, AwaitingInterface } from '@app/providers/store/session/models';
import { ResponseInterface } from 'typings';

/**
 *
 * @description Add evarpay user to the awaiting list
 * @function signIn
 * @property Pick<ISessionUser, 'email_address'> - payload
 * @returns Promise<AwaitingInterface>
 */
export const joinAwaitingList = async (
  payload: Pick<ISessionUser, 'email_address' | 'full_name'>
): Promise<AwaitingInterface> => {
  const { data } = await network.post<ResponseInterface<AwaitingInterface>>('/auth/join', {
    ...payload,
  });
  return data.payload;
};

/**
 *
 * @description Add evarpay user to the awaiting list
 * @function signIn
 * @property { access_token: string } - payload
 * @returns Promise<AwaitingInterface>
 */
export const verifyAwaitingList = async (verify_token: string): Promise<AwaitingInterface> => {
  const { data } = await network.get<ResponseInterface<AwaitingInterface>>('/auth/join', {
    params: { verify_token },
  });

  return data.payload;
};
