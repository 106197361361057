import styled from 'styled-components';
import { Input as InputComponent } from '@app/components/atoms';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .text--wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .email--text {
    font-size: 1.3rem;
  }

  .card--wrapper {
    width: 45rem;
    position: relative;

  
    @media (max-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
    width: 35rem;
  }

  }
`;

export const LogoContainer = styled.div`
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.WHITESMOKE_COLOR};
  > * {
    font-size: 0.6rem;
  }
`;

export const Card = styled.div`
  z-index: 2;
  max-width: 45rem;
  max-height: 55rem;
  position: relative;
  padding: 5rem 3rem;
  border-radius: 16px;
  backdrop-filter: blur(11.2px);
  -webkit-backdrop-filter: blur(11.2px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.17);
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  background: ${({ theme }) => theme.hexToRGB(theme.colors.WHITE_COLOR, 0.2)};

  &::before {
    z-index: 3;
    top: 12rem;
    content: '';
    width: 35rem;
    height: 35rem;
    left: 11rem;
    filter: blur(5rem);
    border-radius: 50%;
    position: absolute;
    background-color: ${({ theme }) => theme.hexToRGB(theme.colors.MEDIUMSLATEBLUE_COLOR, 0.3)};
  }
`;

export const MetricsContainer = styled.div`
  top: -5rem;
  right: -7rem;
  position: absolute;
`;

export const MetricsBottomWrapper = styled.div`
  z-index: 1;
  right: -10rem;
  bottom: -8.5rem;
  position: absolute;
`;

export const ExpiryText = styled.p`
  max-width: 27ch;
  line-height: 1.6;
  margin: 3.5rem 0;
  font-size: 1.8rem;
  text-align: center;

  .timer {
    color: ${({ theme }) => theme.colors.CORAL_COLOR};
  }
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .logo--holder {
    width: 4rem;
    height: 4rem;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.WHITESMOKE_COLOR};
  }
`;

export const Input = styled(InputComponent)`
  width: 100%;
  font-size: 0.1rem;
  max-height: 4.7rem;
  border: none;

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};
    }
  }

  .form-control {
    padding-right: 1rem;
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }
`;

export const BarcodeWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .scan--text {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.3rem;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 4.5rem;
  outline: none;
  font-size: 1.6rem;
  margin-top: 1.5rem;
  border-radius: 1rem;
  padding: 0.625rem 1.25rem;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.WHITESMOKE_COLOR};
  background-color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};

  :disabled {
    pointer-events: none;
  }

  :focus {
    color: ${({ theme }) => theme.colors.WHITESMOKE_COLOR};
  }
`;

export const WhiteButton = styled(Button)`
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  background-color: ${({ theme }) => theme.hexToRGB(theme.colors.WHITESMOKE_COLOR, 0.3)};

  :focus {
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }
`;

export const WalletWrapper = styled.div`
  gap: 3rem;
  display: flex;
  align-items: center;

  :nth-child(2) {
    margin-top: 1.7rem;
  }

  .hex {
    &-background {
      display: flex;
    }

    &--shape {
      display: flex;
      font-weight: 600;
      align-items: center;
      justify-content: center;
      background-color: #fff1ed;
      font-family: ${({ theme }) => theme.fonts.JURA};
    }
  }

  .hex--shape,
  .hex--image {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-sizing: content-box;
    border: 2px solid ${({ theme }) => theme.colors.HOME_BLACK};
    &:not(:last-child) {
      margin-right: -1.5rem;
    }
  }
`;

export const WalletContainer = styled.div`
  display: flex;
  height: 10rem;
  font-size: 1.3rem;
  padding: 0 2.5rem;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.HOME_BLACK};

  .wallet_name {
    margin-left: 0.6rem;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.GHOST_WHITE_COLOR};
  }

  .wallet_usage {
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.LIGHTSLATEGRAY_COLOR};
  }

  .date--text {
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.LIGHTSLATEGRAY_COLOR};
  }

  .image--wrapper {
    display: flex;
    align-items: center;
  }

  .wallet--image {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1rem;
  }
`;

export const SpentCard = styled(WalletContainer)`
  width: 25rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .amount--text {
    font-weight: 700;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.WHITE_COLOR};
  }

  .spent--text {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    color: ${({ theme }) =>
      theme.isDarkMode ? theme.colors.WHITE_COLOR : theme.colors.LIGHTSLATEGRAY_COLOR};
  }

  .icon_holder {
    padding: 1.7rem;
    border-radius: 1.4rem;
    background-color: ${({ theme }) => theme.colors.LIGHT_CYAN};

    svg {
      path {
        fill: #00a58a;
      }
    }
  }
`;
