/*
 ****************************************************************
 ******************    ALL APP CONSTANTS   **********************
 ****************************************************************
 */

/**
 * extending app local storage
 * @constant USER_FIRST_LAUNCH default value
 */
export const USER_FIRST_LAUNCH: string = '@FIRST_TIME_LAUNCH';

/**
 * extending app global constants for page loading
 * @constant DEFAULT_PAGINATION default value
 * @constant LANGUAGE_DEFAULT default value
 */
export const LANGUAGE_DEFAULT: string = 'en';

export const faqResponse = [
  {
    header: 'What is Evarfinance?',
    content:
      'Evarfinance is a blockchain payment infrastructure that allows businesses, creators and digital asset owners accept payment globally in cryptocurrency from anywhere in the world in less than 5 minutes.',
  },
  {
    header: 'How does Evarfinance work?',
    content:
      'Creators, business owners and freelancers can receive payment using payment links. Create an account and start receiving crypto payment in minutes.',
  },
  {
    header: 'Who can use Evarfinance?',
    content:
      'Anyone who offers a service or sells a product can use Evarfinance. Writers, designers, digital assets owners, freelancers, business owners. Anyone.',
  },
  {
    header: 'How do we receive payment through Crypto?',
    content:
      'Through stablecoins only. Your customers or clients can only pay you through Stablecoins',
  },
  {
    header: 'What are StableCoins?',
    content:
      'A “stablecoin” is a type of cryptocurrency whose value is pegged to another asset class, such as a fiat currency or gold, to stabilize its price. They are less volatile and do not dip.',
  },
  {
    header: 'What StableCoins do you currently support?',
    content: 'BUSD and USD',
  },
  {
    header: 'Do my customers have to own an account before they make payment?',
    content:
      'No. Your clients or customers do not need to own an account on Evarfinance before they make payment to you. Simply generate and share your payment links to your clients to enable them access the payment gateway.',
  },
  {
    header: 'What is the difference between the one time payment link and reusable payment link?',
    content:
      'One time payment link allows you to receive payment once from your clients while the reusable payment link allows you to receive payment multiple times from different clients or customers.',
  },
  {
    header: 'How can I reach out to the customer service team?',
    content:
      'Reach out to our customer service via hello@evarfinance.com or and on all our social media channels.',
  },
  {
    header: 'What is your Payout rate?',
    content:
      'Crypto Payout rates on Evarfinance are instant depending on your location or country of residence.',
  },
];
