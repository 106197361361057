import styled from 'styled-components';
import { Link as LinkScroll } from 'react-scroll';
import { NavLink } from 'react-router-dom';

export const Container = styled.header<{ shadow?: boolean }>`
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 999;
  padding: 2rem;
  font-weight: 700;
  position: fixed;
  font-size: 1.6rem;
  align-items: center;
  transition: all 0.2s ease-out;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.JURA};
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  background-color: ${({ theme }) => theme.colors.WHITE_COLOR};
  box-shadow: ${({ shadow }) => (shadow ? '0 0 7px 0 #ced3da' : null)};

  .logo {
    &--icon {
      width: 7rem;
      height: auto;
      margin-left: -1.8rem;
    }
    &--wrapper {
      display: flex;
      z-index: 100;
      position: relative;
      align-items: center;
    }
    &--text {
      font-size: 3rem;
      font-weight: bold;
      margin-left: -1.5rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    height: 10rem;
    padding: 5rem 8vw;
  }
`;

export const NavContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const NavLinks = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    gap: 2rem;
    margin: 0 auto;
    flex-direction: row;
  }
  .nav--list {
    @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
      gap: 3rem;
      display: flex;
      font-size: 1.5rem;
      align-items: center;
      text-transform: uppercase;
    }
    &__item {
      @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 4rem;
      }
      &::after {
        @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
          content: '';
          z-index: 1;
          width: 0.5rem;
          aspect-ratio: 1;
          position: absolute;
          margin-top: 0.5rem;
          border-radius: 50%;
          margin-left: 1.2rem;
          background-color: ${({ theme }) => theme.colors.BLACK_COLOR};
        }
      }
    }
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }
`;

export const AuthWrapper = styled.div`
  .navlink {
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }
  .active {
    width: 100%;
    text-align: center;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    color: ${({ theme }) => theme.colors.WHITESMOKE_COLOR};
    background-color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};
    @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
      width: 17rem;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    gap: 2.8rem;
    display: flex;
    align-items: center;
  }
`;

export const Hamburger = styled.button<{ show: boolean }>`
  display: flex;
  width: 4rem;
  border: none;
  right: 2.5rem;
  outline: none;
  align-items: center;
  height: fit-content;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
  .hamburger--bar {
    z-index: 99;
    width: 3.5rem;
    height: 4px;
    position: relative;
    border-radius: 2rem;
    transform-origin: 8px;
    transition: all 0.2s linear;
    background-color: ${({ theme }) => theme.colors.BLACK_COLOR};
    :nth-child(1) {
      transform: ${({ show }) => (show ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      transform: ${({ show }) => (show ? 'rotate(-45deg)' : 'rotate(0)')};
      margin-top: 0.6rem;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    display: none;
  }
`;

export const DarkModeToggle = styled.div`
  display: none;
  width: 5rem;
  height: 4rem;
  opacity: 0.7;
  cursor: pointer;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.hexToRGB(theme.colors.WHITESMOKE_COLOR, 0.4)};
  @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LinkItem = styled(LinkScroll)`
  cursor: pointer;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  :active,
  :hover {
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }
  ::after {
    :nth-child(1) {
      @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
        content: '';
        z-index: 1;
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        margin-top: 0.5rem;
        border-radius: 50%;
        margin-left: 0.5rem;
        background-color: ${({ theme }) => theme.colors.BLACK_COLOR};
      }
    }
  }
`;
export const Link = styled(NavLink)`
  cursor: pointer;
  font-size: 1.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  :active,
  :hover {
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }
`;

export const AnchorLink = styled.a`
  cursor: pointer;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  :active,
  :hover {
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }
  ::after {
    :nth-child(1) {
      @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
        content: '';
        z-index: 1;
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        margin-top: 0.5rem;
        border-radius: 50%;
        margin-left: 0.5rem;
        background-color: ${({ theme }) => theme.colors.BLACK_COLOR};
      }
    }
  }
`;
