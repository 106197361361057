import styled from 'styled-components';
import { Link as LinkScroll } from 'react-scroll';

export const Container = styled.div<{ show: boolean }>`
  width: 100%;
  z-index: 50;
  height: 100vh;
  position: fixed;
  font-size: 1.6rem;
  padding: 15rem 5rem 0;
  transition: 0.3s ease-in-out;
  top: ${({ show }) => (show ? '0' : '-100%')};
  opacity: ${({ show }) => (show ? '100%' : '0')};
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  background-color: ${({ theme }) => theme.colors.WHITE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    display: none;
  }
`;

export const NavLinks = styled.div`
  gap: 6rem;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const LinkItem = styled(LinkScroll)`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  text-transform: uppercase;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    :nth-child(1),
    :nth-child(2),
    :nth-child(3),
    :nth-child(4) {
      margin-bottom: 5em;
    }
  }

  :active {
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }
`;

export const AuthWrapper = styled.div`
  gap: 6rem;
  width: 100%;
  gap: 2.8rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  .navlink {
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
      margin-bottom: 2.8rem;
    }
  }

  .active {
    width: 100%;
    text-align: center;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    color: ${({ theme }) => theme.colors.WHITESMOKE_COLOR};
    background-color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};
  }
`;

export const DarkModeToggle = styled.div`
  display: flex;
  width: 5rem;
  height: 4rem;
  opacity: 0.7;
  cursor: pointer;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  background-color: ${({ theme }) => theme.hexToRGB(theme.colors.WHITESMOKE_COLOR, 0.4)};

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    margin-top: 5rem;
  }
`;
