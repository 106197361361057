import { useTheme } from 'styled-components';
import { IconProps } from '../Icon.interface';

const Barcode = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg width={60} height={60} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M42.357 0v17.647h17.648V0H42.356Zm14.118 14.118H45.887V3.529h10.588v10.589Z"
        fill={colors[color || 'BLACKRUSSIAN']}
      />
      <path
        d="M49.412 7.059h3.53v3.53h-3.53v-3.53ZM42.357 60h17.648V42.353H42.356V60Zm3.53-14.118h10.588v10.589H45.887V45.882Z"
        fill={colors[color || 'BLACKRUSSIAN']}
      />
      <path
        d="M49.412 49.412h3.53v3.53h-3.53v-3.53ZM0 60h17.647V42.353H0V60Zm3.53-14.118h10.588v10.589H3.529V45.882Z"
        fill={colors[color || 'BLACKRUSSIAN']}
      />
      <path
        d="M7.06 49.412h3.53v3.53H7.06v-3.53ZM21.182 56.47h8.823V60h-8.823v-3.53ZM21.182 49.412h5.294v3.53h-5.294v-3.53ZM10.588 35.294h3.53v3.53h-3.53v-3.53ZM7.059 35.294h-3.53v-7.059H0v10.589h7.059v-3.53ZM56.469 35.294h-3.53v3.53H60V28.235h-3.53v7.06ZM42.357 35.294h7.06v3.53h-7.06v-3.53ZM30 35.294h8.824v3.53H30v-3.53ZM33.533 56.47h5.294V60h-5.294v-3.53ZM35.294 49.412H30v3.53h8.824v-10.59h-3.53v7.06ZM21.172 45.883h8.823v-3.53h-5.294v-7.059h-7.058v3.53h3.529v7.059ZM33.533 7.059h5.294v3.53h-5.294v-3.53ZM42.357 21.176h3.53v3.53h-3.53v-3.53ZM42.357 28.235h3.53v3.53h-3.53v-3.53Z"
        fill={colors[color || 'BLACKRUSSIAN']}
      />
      <path
        d="M49.412 31.765h3.53v-7.06H60v-3.529H49.412v10.589ZM30 17.647h5.294v14.118h3.53V14.117H30v3.53ZM14.121 31.765h15.882v-3.53H17.651v-3.53h-3.53v7.06ZM7.059 31.765h3.53V21.176H0v3.53h7.059v7.059ZM14.121 14.117h3.53v7.06h-3.53v-7.06ZM21.182 21.176h8.823v3.53h-8.823v-3.53ZM24.711 10.588h5.294v-3.53h-8.823v10.59h3.529v-7.06ZM30 0h8.824v3.53H30V0ZM3.53 3.53h7.058V0H0v10.588h3.53V3.53ZM21.182 0h5.294v3.53h-5.294V0ZM7.06 10.588H17.65V0h-3.53v7.059H7.061v3.53ZM0 14.117h10.588v3.53H0v-3.53Z"
        fill={colors[color || 'BLACKRUSSIAN']}
      />
    </svg>
  );
};

export default Barcode;
