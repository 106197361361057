import { defineMessages } from 'react-intl';

const messages = defineMessages({
  close: {
    id: 'action_modal.close',
    defaultMessage: 'Close',
  },
  try_again: {
    id: 'action_modal.try_again',
    defaultMessage: 'Try Again',
  },
  confirm: {
    id: 'action_modal.confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'action_modal.cancel',
    defaultMessage: 'Cancel',
  },
});

export default messages;
