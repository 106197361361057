import { useTheme } from 'styled-components';
import { IconProps } from '../Icon.interface';

const ArrowLeft = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.375 10h11.25M8.75 5.625 4.375 10l4.375 4.375"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={colors[color || 'GRAY_COLOR']}
      />
    </svg>
  );
};

export default ArrowLeft;
