import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Input as InputComponent, Button as ButtonComponent } from '@app/components/atoms';

export const Wrapper = styled.main`
  flex: 1;
  margin-top: 20rem;

  .paymentform--wrapper {
    margin: 10rem 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT}) {
      margin: 0;
    }
  }
`;

export const PaymentText = styled.p`
  line-height: 1.4;
  max-width: 20ch;
  font-size: 5rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fonts.JURA};
  color: ${({ theme }) => theme.colors.BLACK_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
    font-size: 5.5rem;
  }
`;

export const Section = styled.section`
  display: flex;
  padding: 0 8vw;
  margin-top: 10rem;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    gap: 5rem;
    margin-bottom: 7rem;
    flex-direction: row;
  }

  > * {
    flex-basis: 100%;
  }
`;

export const IntroSection = styled(Section)`
  align-items: center;
  justify-content: center;
`;

export const IntroContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > * {
    flex-basis: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE_X_PLUS}) {
    width: 320px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.SMALL_MOBILE}) {
    width: 280px;
  }
`;

export const AboutContainer = styled.div`
  gap: 3rem;
  display: flex;
  padding: 0 4rem;
  margin-top: 12rem;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    flex-direction: row;
  }
`;

export const PaymentSubText = styled.p`
  line-height: 2;
  font-size: 1.6rem;
  margin-top: 3.2rem;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  font-family: ${({ theme }) => theme.fonts.RUBRIK};
`;

export const Input = styled(InputComponent)`
  border: none;
  border-radius: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.RUBRIK};
  background-color: ${({ theme }) => theme.colors.WHITE_COLOR};
`;

export const Button = styled(ButtonComponent)`
  width: 15rem;
  height: 5rem;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.JURA};
`;

export const Form = styled.form`
  display: flex;
  max-width: 65rem;
  padding: 0 0.5rem;
  margin-top: 4rem;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid #70707057;

  &:focus-within {
    border: 2px solid ${({ theme }) => theme.hexToRGB(theme.colors.MEDIUMSLATEBLUE_COLOR, 0.4)} !important;
  }

  .form--input {
    flex: 1;
    height: 6rem;
  }
`;

export const ImageGallery = styled.div`
  display: flex;
  max-height: 378px;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  .globe-image {
    object-fit: cover;

    scale: 0.4;
    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      scale: 0.8;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.LARGE_VIEWPORT}) {
      scale: 1;
    }
  }
`;

export const About = styled.div`
  margin-top: 4rem;
  flex-basis: 100%;
`;

export const Paragraph = styled.p`
  line-height: 1.5;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  font-family: ${({ theme }) => theme.fonts.RUBRIK};
`;

export const IntroHeader = styled.p`
  font-size: 1.6rem;
  position: relative;
  margin-left: 3.5rem;
  margin-bottom: 1.7rem;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.JURA};
  color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};

  &::before {
    top: 50%;
    content: '';
    width: 3rem;
    left: -3.5rem;
    height: 0.2rem;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};
  }
`;

export const GetStartedButton = styled.button`
  outline: none;
  border: none;
  font-weight: 700;
  cursor: pointer;
  font-size: 1.6rem;
  position: relative;
  background: transparent;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  font-family: ${({ theme }) => theme.fonts.JURA};

  ::before {
    top: -5px;
    content: '>';
    left: -4px;
    width: 3rem;
    height: 3rem;
    color: #fff;
    opacity: 0.2;
    display: flex;
    position: absolute;
    border-radius: 50%;
    align-items: center;
    transition: all 0.3s;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};
  }

  :hover::before {
    opacity: 1;
    transform: translateX(16rem);
  }
`;

export const AppointmentButton = styled(GetStartedButton)`
  :hover::before {
    transform: translateX(25rem);
  }
`;

export const GetStarted = styled.div`
  margin-top: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
    margin-top: 3.6rem;
  }
`;

export const AboutHeader = styled(PaymentText)`
  font-size: 3.5rem;
  line-height: 1.4;
  margin-bottom: 2.5rem;
`;

export const VerifySection = styled(Section)`
  gap: 3rem;

  .mt {
    gap: 6rem;
    display: flex;
    flex-basis: 40%;
    align-self: center;
    flex-direction: column;
  }

  .order {
    order: -1;
    flex-basis: 60%;
    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      order: 0;
    }
  }

  .side--product {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .product--text {
    font-size: 1.3rem;
    text-align: center;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
    font-family: ${({ theme }) => theme.fonts.RUBRIK};
  }
`;

export const ProductHeader = styled.p`
  font-weight: 700;
  font-size: 2.5rem;
  margin: 1.5rem 0;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.JURA};
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
`;

export const BannerSection = styled.section`
  padding: 0 4rem;
  margin-top: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    padding: 0 8vw;
  }
`;

export const Banner = styled.div`
  width: 100%;
  color: #fff;
  display: flex;
  max-width: 140rem;
  font-size: 1.8rem;
  align-items: center;
  padding: 6rem 3.5rem;
  border-radius: 1.7rem;
  flex-direction: column;
  justify-content: center;
  background-size: contain;
  background-color: #0a090c;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url('https://res.cloudinary.com/dn8vprize/image/upload/v1664489608/bitcoin_qujkli.svg');

  .banner-header {
    font-size: 3rem;
    flex-basis: 100%;
    line-height: 1.7;
    font-family: ${({ theme }) => theme.fonts.JURA};

    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      font-size: 3.5rem;
      margin-top: -1.7rem;
    }
  }

  .banner-text {
    line-height: 3rem;
    font-size: 2rem;
    letter-spacing: 0.19px;
    font-family: ${({ theme }) => theme.fonts.RUBRIK};
  }

  .banner--wrapper {
    gap: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
    align-items: flex-start;
  }
`;

export const CTAButton = styled(NavLink)`
  color: #fff;
  max-width: 20rem;
  margin-top: 2.5rem;
  border-radius: 1rem;
  padding: 1.7rem 1.5rem;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};

  :focus,
  :hover {
    color: ${({ theme }) => theme.colors.WHITE_COLOR};
  }
`;

export const SectionHeader = styled.p`
  line-height: 1.4;
  font-size: 4.5rem;
  text-align: center;
  margin-top: 0.6rem;
  font-family: ${({ theme }) => theme.fonts.JURA};
  color: ${({ theme }) => theme.colors.BLACK_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    font-size: 6rem;
    line-height: 1;
  }
`;

export const TestimonialHeader = styled(SectionHeader)`
  text-align: left;
`;
export const SectionIntro = styled.p`
  font-size: 1.6rem;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.JURA};
  color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};

  &::before,
  &::after {
    top: 50%;
    content: '';
    width: 3rem;
    height: 0.2rem;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};
  }

  &::before {
    left: -3rem;
  }
`;

export const TestimonialWrapper = styled.section`
  margin-top: 20rem;
`;

export const SectionHeaderContainer = styled.div`
  margin: 5rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const SectionSubText = styled.p`
  line-height: 1.7;
  max-width: 79ch;
  font-size: 1.8rem;
  text-align: center;
  margin-top: 3.5rem;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  font-family: ${({ theme }) => theme.fonts.RUBRIK};
`;

export const PricingCard = styled.div`
  width: 40rem;
  height: 60rem;
  padding: 2rem;
  font-size: 1.7rem;
  border-radius: 1.5rem;
  color: ${({ theme }) => theme.colors.WHITE_COLOR};
  background-color: ${({ theme }) => theme.colors.HOME_BLACK};
`;

export const PricingCardContainer = styled.div`
  display: flex;
  padding: 4rem;
  margin-right: 4rem;
  overflow-x: auto;
  min-width: 50rem;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
    justify-content: center;
  }
`;

export const PricingSection = styled(TestimonialWrapper)``;

export const ContactSection = styled(PricingSection)`
  margin: 10rem 0 7rem 0;
  padding: 0 4rem;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};

  .contact-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .contact--input {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    padding-left: 1rem;
  }

  .input--wrapper {
    display: flex;
    margin-top: 3rem;
    font-size: 1.8rem;
    margin-bottom: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &__item {
      display: flex;
      flex-basis: 100%;
      align-items: center;

      :nth-child(2) {
        width: 100%;
        margin-top: 2rem;
        flex-direction: column;
        align-items: flex-start;
        @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
          margin-top: 0;
          flex-direction: row;
        }
      }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      flex-direction: row;
      align-items: center;
    }
  }

  .input--text {
    font-size: 2rem;
    white-space: nowrap;

    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      font-size: 3rem;
    }

    :nth-child(2) {
      text-align: left;

      @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
        flex-direction: row;
      }
    }
  }

  .input--control {
    display: flex;
    margin-top: 2rem;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      align-items: center;
      flex-direction: row;
    }
  }

  .dropdown {
    align-self: center;
    margin-top: 1.5rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      align-self: center;
      margin-top: 0;
    }
  }

  .emailinput-container {
    margin-left: 1rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      width: 35%;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
    padding: 0 8vw;
  }
`;

export const TestimonialInput = styled(InputComponent)`
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  background: transparent;
  border-bottom: 2px solid ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};

  &:focus-within {
    border-bottom: 2px solid
      ${({ theme }) => theme.hexToRGB(theme.colors.MEDIUMSLATEBLUE_COLOR, 0.4)} !important;
  }

  .form-control {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    font-weight: normal;
    ::placeholder {
      text-align: center;
    }
  }

  :nth-child(2) {
    margin-left: 1.5rem;
  }
`;

export const Image = styled.img.attrs({
  loading: 'lazy',
  width: '100%',
  height: '100%',
})`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;

export const Article = styled.article`
  padding: 0 8vw;
  margin-top: 10rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_LARGE_VIEWPORT}) {
    margin-bottom: 7rem;
  }

  h2 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1.4rem;
    font-family: ${({ theme }) => theme.fonts.JURA};
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }

  .flex {
    gap: 4rem;
    display: flex;
    margin-top: 5rem;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      flex-direction: row;
    }
  }
`;

export const ArticleText = styled(Paragraph)`
  :not(:last-child) {
    margin-bottom: 1.7rem;
  }
`;

export const ArticleCard = styled.div`
  flex-basis: 100%;
  padding: 3rem 4rem;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.GHOST_WHITE_COLOR};
  transition: all 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;
