import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: none;
  outline: none;
  cursor: default;
  position: relative;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};

  .arrow {
    height: 0;
    top: 14px;
    width: 0;
    content: '';
    right: 10px;
    display: block;
    border-style: solid;
    margin-top: 0.3rem;
    position: absolute;
    border-width: 0.7rem 0.7rem 0;
    border-color: ${({ theme }) => theme.colors.BLACK_COLOR} transparent transparent;
  }

  .arrow.open {
    border-width: 0 0.7rem 0.7rem;
    border-color: transparent transparent ${({ theme }) => theme.colors.BLACK_COLOR};
  }

  .selected-value {
    border: none;
    outline: none;
    width: 100%;
    line-height: 1.5;
    font-size: 1.6rem;
    text-align: center;
    white-space: nowrap;
    transition: all 200ms ease;
    padding: 8px 52px 8px 10px;
    color: ${({ theme }) => theme.colors.LIGHTSLATEGRAY_COLOR};
    background-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
  }

  .options {
    top: 100%;
    width: 100%;
    display: none;
    z-index: 10;
    margin-top: -1px;
    overflow-y: auto;
    max-height: 200px;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  .options.open {
    display: block;
  }

  .option {
    cursor: pointer;
    display: block;
    font-size: 1.4rem;
    text-align: right;
    padding: 1rem 1.5rem;
    font-family: ${({ theme }) => theme.fonts.JURA};
    color: ${({ theme }) => theme.colors.WHITE_COLOR};
    background-color: ${({ theme }) => theme.colors.BLACKRUSSIAN};
  }

  .option.selected {
    color: ${({ theme }) => theme.colors.WHITE_COLOR};
    background-color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }
`;
