import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from 'react-icons/hi';

import messages from './messages';
import { faqResponse } from '@app/constants';

import {
  FAQList,
  FAQTitle,
  Container,
  FAQHeader,
  BottomCurve,
  TopLeftCurve,
  FAQContainer,
  TopRightCurve,
  DetailsWrapper,
} from './Faq.styles';

export const Faq: FC<{}> = () => {
  const [show, setShow] = useState(false);
  const [itemCount, setItemCount] = useState(0);

  return (
    <Container>
      <TopRightCurve />
      <TopLeftCurve />
      <FAQContainer>
        <FAQTitle>
          <FormattedMessage {...messages.title} />
        </FAQTitle>
        <FAQList>
          {faqResponse.map(({ header, content }, index) => (
            <li className="faq-item" key={index.toString()}>
              <FAQHeader
                onClick={() => {
                  setItemCount(index);
                  setShow(!show);
                }}
              >
                <p className="header">{header}</p>
                <div className="icon">
                  {index === itemCount && show ? <HiOutlineMinusCircle /> : <HiOutlinePlusCircle />}
                </div>
              </FAQHeader>

              <DetailsWrapper active={index === itemCount && show}>
                <p>{content}</p>
              </DetailsWrapper>
            </li>
          ))}
        </FAQList>
      </FAQContainer>

      <BottomCurve />
    </Container>
  );
};
