import { IconProps } from '../Icon.interface';

const Moon = ({ color, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={22.372} height={22.372} {...props}>
      <g data-name="Group 45">
        <path
          data-name="Path 182"
          d="M6.925 0C2.525 1.63 0 5.865 0 10.83a11.542 11.542 0 0 0 11.543 11.542c4.964 0 9.2-2.527 10.83-6.925a11.358 11.358 0 0 1-4.253.8A12.433 12.433 0 0 1 6.127 4.254a11.359 11.359 0 0 1 .8-4.253Z"
          fill="#090429"
        />
      </g>
    </svg>
  );
};

export default Moon;
