// import original module declarations
import 'styled-components';

export enum BREAK_POINTS {
  MOBILE = '37.5em', //600px
  SMALL_MOBILE = '22.5em', //360px/16
  LARGE_VIEWPORT = '62em', //992px/16
  SMALL_VIEWPORT = '36em', //576px
  MEDIUM_VIEWPORT = '48em', //768px/16
  EXTRA_LARGE_VIEWPORT = '75em', //1200px tab land
  LARGE_VIEWPORT_IPAD_PRO = '64em', //1024px
  EXTRA_LARGE_DESKTOP_VIEW = '90em', //1440px
  EXTRA_EXTRA_LARGE_DESKTOP_VIEW = '96em', //1536px
  EXTRA_SMALL_VIEWPORT_PIXEL_XL = '25.7em', //411px
  EXTRA_SMALL_VIEWPORT_IPHONE_X = '375px',
  EXTRA_SMALL_VIEWPORT_IPHONE_X_SE = '319px',
  EXTRA_SMALL_VIEWPORT_IPHONE_X_PLUS = '25.9em', //414px
  EXTRA_SMALL_VIEWPORT_IPHONE_X_HEIGHT = '812px',
  EXTRA_SMALL_VIEWPORT_IPHONE6_7_8_HEIGHT = '41.7em', //667px
}

// All app colors
export enum LIGHT_MODE_COLORS {
  DARKCYAN = '#007993',
  RED_COLOR = '#f41515',
  PALE_GRAY = '#FBFCFC',
  GRAY_COLOR = '#7E8683',
  HOME_BLACK = '#0a090c',
  CARD_BLACK = '#090429',
  MILK_WHITE = '#FBFCFC',
  LIGHT_CYAN = '#0f1617',
  WHITE_COLOR = '#FFFFFF',
  CORAL_COLOR = '#FF805C',
  ERROR_COLOR = '#FA235D',
  TEXT_COLOR = '#989CA5',
  SADDLEBROWN = '#9a5e00',
  BLACK_COLOR = '#20262b',
  BLACKRUSSIAN = '#04001E',
  ORANGE_COLOR = '#EFA106C',
  BORDER_GRAY = '#70707034',
  THISTLE_COLOR = '#CFC8FB',
  DIM_GRAY_COLOR = '#586068',
  SEASHELL_COLOR = '#FFF1ED',
  MEDIUMVIOLETRED = '#cb00aa',
  GAINSBORO_COLOR = '#DFE1E1',
  POWERBLUE_COLOR = '#B5E0E9',
  PLACEHOLDER_GRAY = '#A1A5AD',
  PAPAYAWHIP_COLOR = '#FDEDD4',
  WHITESMOKE_COLOR = '#EFEEF9',
  GHOST_WHITE_COLOR = '#F7F6FF',
  MEDIUMEAGREEN_COLOR = '#09C374',
  PALETURQUOISE_COLOR = '#97FFEE',
  LAVENDERBLUSH_COLOR = '#FDE4F9',
  DARKSLATEBLUE_COLOR = '#574BA0',
  DARKSLATEGRAY_COLOR = '#4D514F',
  LIGHTSLATEGRAY_COLOR = '#929292',
  MEDIUMSLATEBLUE_COLOR = '#7D69FF',
  TRANSPARENT_COLOR = 'transparent',
  POWERBLUE_LIGHT_COLOR = '#C8EEF5',
  INVERSE_DIM_GRAY_COLOR = '#cccccc',
  PAPAYAWHIP_LIGHT_COLOR = '#FFF5E4',
  LAVENDERBLUSH_LIGHT_COLOR = '#FFEDFC',
  PALETURQUOISE_LIGHT_COLOR = '#C0FFF5',
}

export enum DARK_MODE_COLORS {
  DARKCYAN = '#007993',
  RED_COLOR = '#f41515',
  PALE_GRAY = '#fbfcfc',
  MILK_WHITE = '#1F1F1F',
  GRAY_COLOR = '#FFFFFF',
  HOME_BLACK = '#ffffff',
  TEXT_COLOR = '#989CA5',
  CARD_BLACK = '#0A090C',
  LIGHT_CYAN = '#d1ffff',
  ERROR_COLOR = '#FA235D',
  WHITE_COLOR = '#20262b',
  CORAL_COLOR = '#FF805C',
  BLACK_COLOR = '#FFFFFF',
  BLACKRUSSIAN = '#EFEEF9',
  ORANGE_COLOR = '#EFA106',
  BORDER_GRAY = '#70707034',
  THISTLE_COLOR = '#CFC8FB',
  SEASHELL_COLOR = '#FFF1ED',
  DIM_GRAY_COLOR = '#586068',
  MEDIUMVIOLETRED = '#cb00aa',
  GAINSBORO_COLOR = '#DFE1E1',
  POWERBLUE_COLOR = '#B5E0E9',
  PAPAYAWHIP_COLOR = '#FDEDD4',
  PLACEHOLDER_GRAY = '#ffffff',
  WHITESMOKE_COLOR = '#EFEEF9',
  GHOST_WHITE_COLOR = '#2b343b',
  MEDIUMEAGREEN_COLOR = '#09C374',
  PALETURQUOISE_COLOR = '#97FFEE',
  LAVENDERBLUSH_COLOR = '#FDE4F9',
  DARKSLATEBLUE_COLOR = '#574BA0',
  DARKSLATEGRAY_COLOR = '#FFFFFF',
  LIGHTSLATEGRAY_COLOR = '#929292',
  POWERBLUE_LIGHT_COLOR = '#C8EEF5',
  TRANSPARENT_COLOR = 'transparent',
  MEDIUMSLATEBLUE_COLOR = '#7D69FF',
  INVERSE_DIM_GRAY_COLOR = '#586068',
  PAPAYAWHIP_LIGHT_COLOR = '#FFF5E4',
  LAVENDERBLUSH_LIGHT_COLOR = '#FFEDFC',
  PALETURQUOISE_LIGHT_COLOR = '#C0FFF5',
}

// All app font sizes
export enum FONTS {
  JURA = 'Jura',
  RUBRIK = 'Rubrik',
  NUNITO = 'Nunito',
}

declare module 'styled-components' {
  export interface DefaultTheme {
    // All Global App Font typings
    fonts: typeof FONTS;
    // App dark mode condition
    isDarkMode: boolean;
    // Toggle App theme
    toggleTheme: () => void;
    // App break points
    breakpoints: typeof BREAK_POINTS;
    // All Global App Colors typings
    colors: typeof DARK_MODE_COLORS | typeof LIGHT_MODE_COLORS;
    // App color converter
    hexToRGB: (hexColor: string, alpha?: number | undefined) => string;
  }
}
