import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Container = styled(Modal)`
  .title {
    line-height: 2.7rem;
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
    font-weight: ${({ size }) => (size === 'sm' ? 700 : 600)};
    font-size: ${({ size }) => (size === 'sm' ? 2.4 : 1.8)}rem;
  }

  .modal-body,
  .modal-header,
  .modal-content,
  .modal-footer {
    border: none;
    background-color: ${({ theme }) => theme.colors.WHITE_COLOR};
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modal-content {
    padding: 3.4rem;
    min-height: 50rem;
    min-width: 49.4rem;
    border-radius: 1rem;
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CloseButton = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 100%;

  :hover {
    background-color: ${({ theme }) =>
      theme.isDarkMode ? theme.colors.GAINSBORO_COLOR : theme.colors.GHOST_WHITE_COLOR};

    svg {
      path {
        stroke: ${({ theme }) =>
          theme.isDarkMode ? theme.colors.DIM_GRAY_COLOR : theme.colors.GRAY_COLOR};
      }
    }
  }
`;

export const DescriptionWrapper = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 4rem;
  padding-right: 6.5rem;
  color: ${({ theme }) => theme.colors.DARKSLATEGRAY_COLOR};
`;
