import { IconProps } from '../Icon.interface';

const Mark = ({ color, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
      <g data-name="Group 44576" transform="translate(-536 -5686)">
        <circle
          data-name="Ellipse 2325"
          cx={15}
          cy={15}
          r={15}
          transform="translate(536 5686)"
          fill="#7d69ff"
        />
        <g data-name="Group 44575" fill="#fff" stroke="#fff" strokeWidth={0.6}>
          <g data-name="Rectangle 3367" transform="rotate(-135.97 1427.89 2741.775)">
            <rect width={5.764} height={0.683} rx={0.342} stroke="none" />
            <rect x={-0.3} y={-0.3} width={6.364} height={1.283} rx={0.642} fill="none" />
          </g>
          <g data-name="Rectangle 3369" transform="rotate(-43 7515.755 2156.273)">
            <rect width={11.164} height={0.683} rx={0.342} stroke="none" />
            <rect x={-0.3} y={-0.3} width={11.764} height={1.283} rx={0.642} fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Mark;
