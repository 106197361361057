import styled, { keyframes } from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';

import { Input as InputAuth, Button as ButtonComponent } from '@app/components/atoms';

export const Button = styled(ButtonComponent).attrs({
  type: 'submit',
  variant: 'primary',
})`
  margin-top: 2rem;
`;

export const SuccessHeader = styled.p`
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  font-family: ${({ theme }) => theme.fonts.JURA};
`;

const SubTextAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: center;
`;

export const AchorTag = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})<{ marginRight?: number }>`
  transition: all 0.3s;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)}rem;

  :hover {
    transform: scale(1.3);
  }
`;

export const CommunityText = styled.p`
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.hexToRGB(theme.colors.BLACK_COLOR, 0.6)};
`;

export const Container = styled.section`
  height: 100%;
  height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.RUBRIK};
`;

export const BottomCurve = styled.img.attrs({
  src: 'https://res.cloudinary.com/dn8vprize/image/upload/v1664489653/bottom-curve_cf5fiq.svg',
})`
  bottom: 0;
  z-index: 1;
  display: none;
  height: 10rem;
  position: absolute;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    display: block;
  }
`;

export const TopRightCurve = styled.img.attrs({
  src: 'https://res.cloudinary.com/dn8vprize/image/upload/v1660251966/cyan-curve_xmj4jl.svg',
})`
  top: 0;
  right: -30px;
  display: none;
  height: 25rem;
  position: absolute;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    display: block;
  }
`;

export const TopLeftCurve = styled.img.attrs({
  src: 'https://res.cloudinary.com/dn8vprize/image/upload/v1660251939/red-curve_nihreo.svg',
})`
  top: 0;
  left: 0;
  display: none;
  height: 20rem;
  position: absolute;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    display: block;
  }
`;

export const Input = styled(InputAuth)`
  height: 5rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.GRAY_COLOR};
  border: 1px solid ${({ theme }) => theme.colors.BORDER_GRAY};
  background-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};

  &:focus-within {
    border: 2px solid ${({ theme }) => theme.hexToRGB(theme.colors.MEDIUMSLATEBLUE_COLOR, 0.4)};
  }
`;

export const InputWrapper = styled.div<{ margin?: number }>`
  margin-bottom: ${({ margin = 1.5 }) => margin}rem;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
  flex-direction: column;
  justify-content: center;
`;

export const LogoContainer = styled(FormHeader)`
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.WHITESMOKE_COLOR};
  > * {
    font-size: 0.7rem;
  }
`;

export const FormTitle = styled.h3`
  font-size: 1.8vw;
  font-weight: 700;
  text-align: center;
  margin: 3rem 0 1rem 0;
  font-family: ${({ theme }) => theme.fonts.JURA};
  color: ${({ theme }) => theme.colors.BLACK_COLOR};

  @media (max-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
    font-size: 4.8vw;
  }
`;

export const FormSubTitle = styled.p`
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.hexToRGB(theme.colors.BLACK_COLOR, 0.6)};

  sup {
    font-size: 1rem;
  }
`;

export const Form = styled.form`
  height: auto;
  z-index: 5;
  width: 100%;
  overflow: hidden;
  overflow: hidden;
  padding: 4rem 1rem;
  position: relative;
  border-radius: 20px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.BORDER_GRAY};
  background-color: ${({ theme }) => theme.colors.WHITE_COLOR};

  @media (min-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE_X_PLUS}) {
    padding: 4rem 4rem 5rem 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    padding: 4rem 5rem 5rem 5rem;
  }

  .input-control {
    width: 100%;
    margin-top: 1.7rem;
    position: relative;
  }

  .forgot--password__container {
    right: 0;
    position: absolute;
  }
`;

export const FormWrapper = styled(Form)``;

export const FormBg = styled.img.attrs({
  src: 'https://res.cloudinary.com/dn8vprize/image/upload/v1660252023/bottom-curve_rjasbb.svg',
})`
  top: 0;
  right: -20rem;
  height: 10rem;
  position: absolute;
  transform: rotate(-145deg);
`;

export const Link = styled(BaseLink)`
  font-weight: 700;
  font-size: 1.7rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};

  :hover {
    color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};
  }
`;

export const SignUpWrapper = styled(FormHeader)`
  margin-top: 4rem;
  font-size: 1.6rem;
  flex-direction: row;
  justify-content: space-between;

  .signup--wrapper__item {
    display: flex;
    align-items: center;

    p {
      margin-right: 0.7rem;
      color: ${({ theme }) => theme.colors.PLACEHOLDER_GRAY};
    }
  }

  .button_item {
    width: 100%;
    max-width: 11rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.EXTRA_SMALL_VIEWPORT_IPHONE_X_PLUS}) {
    flex-direction: column;

    .button_item {
      max-width: 100%;
      margin-top: 2rem;

      button {
        height: 5rem;
      }
    }
  }
`;

export const ReturnWrapper = styled(LogoContainer)`
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  border-radius: 50%;
  flex-direction: row;
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.colors.WHITESMOKE_COLOR};

  svg {
    path {
      stroke: ${({ theme }) =>
        theme.isDarkMode ? theme.colors.WHITE_COLOR : theme.colors.MEDIUMSLATEBLUE_COLOR};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};

    svg {
      path {
        stroke: ${({ theme }) =>
          theme.isDarkMode ? theme.colors.HOME_BLACK : theme.colors.WHITE_COLOR};
      }
    }
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  max-width: 55rem;
  place-items: center;
`;

export const SubText = styled(FormSubTitle)`
  margin-bottom: 1.3rem;
  animation: ${SubTextAnimation} 0.3s ease-in-out;
`;
