import React, { Fragment, useState } from 'react';
import { Icon } from '../Icon';

import { InputBaseProps } from './Input.interface';
import { Container, Label, InputControl, ShowButton, ShowText, ErrorMessage } from './Input.styles';

export const Input: React.FC<InputBaseProps> = ({
  as,
  label,
  type,
  error,
  left_icon,
  right_icon,
  defaultValue,
  disabled = false,
  placeholder = 'Search',
  ...props
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  return (
    <Fragment>
      {label && <Label htmlFor={type}>{label}</Label>}
      <Container {...props} inputtype={as} error={error}>
        {left_icon && <Icon name={left_icon} className="input_left_icon" />}
        <InputControl
          as={as}
          type={passwordShown ? 'text' : type}
          disabled={disabled}
          className="input__field"
          aria-label={placeholder}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
        {type === 'password' && (
          <ShowButton onClick={() => setPasswordShown(!passwordShown)}>
            <ShowText>{passwordShown ? 'Hide' : 'Show'}</ShowText>
          </ShowButton>
        )}
        {right_icon && <Icon name={right_icon} className="input_right_icon" />}
      </Container>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Fragment>
  );
};
