import { useTheme } from 'styled-components';
import { IconProps } from '../Icon.interface';

const ArrowRight = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg width={10} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.461 3.706 6.545.789a.417.417 0 0 0-.59.589l2.206 2.205H.833a.417.417 0 0 0 0 .834h7.328L5.956 6.622a.417.417 0 1 0 .589.59L9.46 4.294a.417.417 0 0 0 0-.59Z"
        fill={colors[color || 'CORAL_COLOR']}
      />
    </svg>
  );
};

export default ArrowRight;
