import { Toaster as Toastify } from 'react-hot-toast';

export const Toaster = () => {
  return (
    <Toastify
      position="top-right"
      toastOptions={{
        style: {
          fontWeight: 600,
          boxShadow: 'none',
          fontSize: '1.5rem',
          padding: '1.3rem 2rem',
        },
        success: {
          duration: 3000,
          style: {
            background: '#eaf9f2',
            border: '1px solid #38c182',
          },
        },
        error: {
          duration: 3000,
          style: {
            background: '#f7eaea',
            border: '1px solid #ff4b4b',
          },
        },
      }}
    />
  );
};
