import { defineMessages } from 'react-intl';

const messages = defineMessages({
  payment_text: {
    id: 'main.payment_text',
    defaultMessage: 'Get connected to the global market in minutes',
  },
  payment_sub_text: {
    id: 'main.payment_sub_text',
    defaultMessage: ` Scale your business across borders and receive international payments seamlessly in
    crypto.`,
  },
  email_placeholder: {
    id: 'main.email',
    defaultMessage: 'Enter your email address',
  },
  get_started: {
    id: 'main.get_started',
    defaultMessage: 'Get started',
  },
  about_evarfinance: {
    id: 'main.about_evarfinance',
    defaultMessage: 'About',
  },
  about_title: {
    id: 'main.about_title',
    defaultMessage: 'Power your business with the ease of crypto',
  },
  about_sub_title: {
    id: 'main.about_sub_title',
    defaultMessage: ` Evarfinance is the partner that drives seamless payments around the world by making
    transactions with cryptocurrency easy.`,
  },
  get_started_now: {
    id: 'main.get_started_now',
    defaultMessage: 'Get started now',
  },
  offering_title: {
    id: 'main.offering_title',
    defaultMessage: 'Our Offering',
  },
  offering_heading: {
    id: 'main.offering_heading',
    defaultMessage: 'Take your payments beyond borders with us',
  },
  offering_sub_heading: {
    id: 'main.offering_sub_heading',
    defaultMessage: ` We power your bussiness with our fast, secure and borderless crypto payments and
    checkout platform.`,
  },
  instant_settlement: {
    id: 'main.instant_settlement',
    defaultMessage: 'Instant settlement',
  },
  instant_settlement_subtext: {
    id: 'main.instant_settlement_subtext',
    defaultMessage: 'Send and receive payment in as little as 5 minutes.',
  },
  transaction_fees: {
    id: 'main.transaction_fees',
    defaultMessage: 'Low transaction fees',
  },
  transaction_fees_subtext: {
    id: 'main.transaction_fees_subtext',
    defaultMessage: 'Pay way less for all your international transactions.',
  },
  secure_transactions: {
    id: 'main.secure_transactions',
    defaultMessage: 'Secure Transactions',
  },
  secure_transactions_subtext: {
    id: 'main.secure_transactions_subtext',
    defaultMessage: `With the use of the latest cutting edge technology, all transactions on our platforms
    are guaranteed safe.`,
  },
  fast_withdrawal: {
    id: 'main.fast_withdrawal',
    defaultMessage: 'Fast Withdrawal',
  },
  fast_withdrawal_subtext: {
    id: 'main.fast_withdrawal_subtext',
    defaultMessage: 'Withdraw funds easily into your crypto wallet.',
  },
  who_is_evarfinance_for: {
    id: 'main.who_is_evarfinance_for',
    defaultMessage: 'Who is EvarFinance for?',
  },
  for_creators: {
    id: 'main.for_creators',
    defaultMessage: 'For Creators',
  },
  for_creators_subtext: {
    id: 'main.for_creators_subtext',
    defaultMessage: `EvarFinance has built a hands on ideal solution that allows you get paid for what you
    love doing.`,
  },
  for_creators_article: {
    id: 'main.for_creators_article',
    defaultMessage: `As a creator, we understand that geographical locations can place a restriction on
    your craft. With a simple payment link, you can now access global payment any day, any
    time.`,
  },
  for_business: {
    id: 'main.for_business',
    defaultMessage: 'For Businesses',
  },
  for_business_subtext: {
    id: 'main.for_business_subtext',
    defaultMessage: `Scale your business beyond the borders of Africa and across the shores of the world.
    No matter where your customers are, never allow payment to be a problem anymore.`,
  },
  for_business_article: {
    id: 'main.for_business_article',
    defaultMessage: ` By simply creating a one time payment link, your customers can now pay for your goods
    and products in split seconds.`,
  },
  for_freelancers: {
    id: 'main.for_freelancers',
    defaultMessage: 'For Freelancers',
  },
  for_freelancers_subtext: {
    id: 'main.for_freelancers_subtext',
    defaultMessage: `Work from the comfort of your home for multiple clients in various countries without
    having to worry about the method of receiving payment.`,
  },
  for_freelancers_article: {
    id: 'main.for_freelancers_article',
    defaultMessage: `EvarFinance has created a payment solution that suits and supports your freelancing
    passion. Now you can offer your services and expertise around the globe.`,
  },
  refund_guarantee: {
    id: 'main.refund_guarantee',
    defaultMessage: 'Refund is guaranteed in case merchant fails to deliver',
  },
  refund_guarantee_article: {
    id: 'main.refund_guarantee_article',
    defaultMessage: `If merchant fails to deliver or delivers an item below the agreed upon standard, you
    will get 100% of your money back!`,
  },
  get_in_touch: {
    id: 'main.get_in_touch',
    defaultMessage: 'Get in Touch',
  },
  schedule_appointment: {
    id: 'main.schedule_appointment',
    defaultMessage: 'Schedule an Appointment',
  },
  my_name: {
    id: 'main.my_name',
    defaultMessage: 'Hey, my name is',
  },
  full_name_placeholder: {
    id: 'main.full_name_placeholder',
    defaultMessage: 'Type here',
  },
  i_want_to: {
    id: 'main.i_want_to',
    defaultMessage: 'and I want to',
  },
  schedule_appointment_email_title: {
    id: 'main.schedule_appointment_email_title',
    defaultMessage: 'Get in touch with me at',
  },
  schedule_appointment_email_placeholder: {
    id: 'main.schedule_appointment_email_placeholder',
    defaultMessage: 'Your email ID here',
  },
});

export default messages;
