import styled from 'styled-components';

export const Container = styled.section`
  height: 100%;
  padding: 1rem;
  display: flex;
  min-height: 100vh;
  position: relative;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.RUBRIK};
`;

export const BottomCurve = styled.img.attrs({
  src: 'https://res.cloudinary.com/dn8vprize/image/upload/v1664489653/bottom-curve_cf5fiq.svg',
})`
  bottom: 0;
  z-index: 1;
  display: none;
  height: 10rem;
  position: absolute;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    display: block;
  }
`;

export const TopRightCurve = styled.img.attrs({
  src: 'https://res.cloudinary.com/dn8vprize/image/upload/v1660251966/cyan-curve_xmj4jl.svg',
})`
  top: 0;
  right: -30px;
  display: none;
  height: 25rem;
  position: absolute;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    display: block;
  }
`;

export const TopLeftCurve = styled.img.attrs({
  src: 'https://res.cloudinary.com/dn8vprize/image/upload/v1660251939/red-curve_nihreo.svg',
})`
  top: 0;
  left: 0;
  display: none;
  height: 20rem;
  position: absolute;

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    display: block;
  }
`;

export const FAQTitle = styled.h2`
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  margin: 2.5rem 0;
  font-family: ${({ theme }) => theme.fonts.JURA};
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
`;

export const FAQContainer = styled.div`
  width: 100%;
  max-width: 65rem;
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
`;

export const FAQList = styled.ul`
  z-index: 10;
  max-height: 50rem;
  overflow-y: scroll;
  position: relative;
  padding: 1rem 2rem;

  .faq-item:not(:last-child) {
    padding: 1rem 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.GHOST_WHITE_COLOR};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MEDIUM_VIEWPORT}) {
    max-height: 60rem;
  }
`;

export const FAQHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  .icon {
    font-size: 3rem;
  }

  .header {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    font-family: ${({ theme }) => theme.fonts.JURA};
  }
`;

export const DetailsWrapper = styled.div<{ active?: boolean }>`
  overflow: hidden;
  margin-top: 7px;
  transition: height 0.4s ease-in-out;
  height: ${({ active }) => (active ? '70px' : '0px')};

  p {
    line-height: 1.5;
    font-size: 1.7rem;
  }
`;
