import { useTheme } from 'styled-components';
import { IconProps } from '../Icon.interface';

const Telegram = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();
  return (
    <svg
      width={24}
      height={24}
      fill={colors[color || 'BLACK_COLOR']}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-3.11-8.83-2.498-.779c-.54-.165-.543-.537.121-.804l9.733-3.76c.565-.23.885.061.702.79l-1.657 7.82c-.116.557-.451.69-.916.433l-2.551-1.888-1.189 1.148c-.122.118-.221.219-.409.244-.187.026-.341-.03-.454-.34l-.87-2.871-.012.008z" />
    </svg>
  );
};

export default Telegram;
