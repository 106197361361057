import styled from 'styled-components';

const Loading = () => <Spinner className="spinner" />;

export const Spinner = styled.div`
  width: 3rem;
  height: 3rem;
  margin: 0rem 2rem;
  border-radius: 50%;
  animation-duration: 1s;
  background: transparent;
  animation-name: rotate-s-loader;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border: 5px solid
    ${({ theme }) => (theme.isDarkMode ? theme.colors.BLACK_COLOR : theme.colors.WHITE_COLOR)};
  border-left-color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};

  @keyframes rotate-s-loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default Loading;
