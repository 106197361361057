import { useState, useEffect } from 'react';
import axios from 'axios';

const usePost = (url: string) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState<object>({});
  const [isLoading, setIsLoading] = useState(true);
  const [submitStatus, setSubmitStatus] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post(url, data);
        if (res.status !== 200) {
          throw new Error('Something went wrong. Try again');
        }
        setSubmitStatus('Submitted Successfully');
        setIsLoading(false);
      } catch (err: any) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url, data]);

  return { error, setData, isLoading, submitStatus };
};

export default usePost;
