import { IColorMode, SwitchScreen, ISettingsState, SettingsActionTypes, Locale } from '../models';
import { SET_SCREEN, SET_LOCALE, SET_COLOR_MODE } from '../types';

const initialState: ISettingsState = {
  colorMode: IColorMode.LIGHT,
  screen: SwitchScreen.AUTH_SCREEN,
  locale: (window?.navigator?.language.trim().split(/-|_/)[0] || Locale.ENGLISH) as Locale,
};

const settingReducer = (
  state: ISettingsState = initialState,
  action: SettingsActionTypes
): ISettingsState => {
  switch (action.type) {
    case SET_COLOR_MODE:
      return { ...state, colorMode: action.payload };

    case SET_SCREEN:
      return { ...state, screen: action.payload };

    case SET_LOCALE:
      return { ...state, locale: action.payload };

    default:
      return state;
  }
};

export default settingReducer;
