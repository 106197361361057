import { useState, useEffect, useRef } from 'react';
import { DropdownProps } from './CustomDropdown.interface';

import { Container } from './CustomDropdown.styles';

export const CustomDropdown = ({
  options,
  onChange,
  className,
  placeholder,
  selectedValue,
}: DropdownProps) => {
  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener('click', close);
    return () => document.removeEventListener('click', close);
  }, []);

  function close(e: any) {
    setOpen(e && e.target === ref.current);
  }

  return (
    <Container className={className}>
      <div className="control" onClick={() => setOpen(!open)}>
        <p className="selected-value" ref={ref}>
          {selectedValue || placeholder}
        </p>
        <div className={`arrow ${open ? 'open' : null}`} />
      </div>

      <ul className={`options ${open ? 'open' : null}`}>
        {options.map((item, index) => (
          <li
            key={index}
            className={`option ${selectedValue === item ? 'selected' : null}`}
            onClick={() => {
              onChange(item);
              setOpen(false);
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </Container>
  );
};
