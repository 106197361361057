import { Fragment, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { Icon } from '@app/components/atoms';
import { MobileMenu } from '../MobileMenu';

import {
  Link,
  LinkItem,
  NavLinks,
  Hamburger,
  Container,
  AuthWrapper,
  NavContainer,
  DarkModeToggle,
} from './Navigation.styles';

export const Navigation = () => {
  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();

  useEffect(() => {
    window.addEventListener('scroll', scrollNav);
    return () => {
      window.removeEventListener('scroll', scrollNav);
    };
  });

  const scrollNav = () => {
    const scrollTop = window.scrollY;
    setScroll(() => (scrollTop >= 100 ? true : false));
  };

  return (
    <Fragment>
      <Container shadow={scroll}>
        <div className="logo--wrapper">
          <Icon name="logo" color="MEDIUMSLATEBLUE_COLOR" className="logo--icon" />
          <p className="logo--text">evarfinance</p>
        </div>

        <NavContainer>
          <NavLinks>
            <ul className="nav--list">
              <li className="nav--list__item">
                <LinkItem to="about" offset={-190}>
                  <FormattedMessage {...messages.about_evarfinance} />
                </LinkItem>
              </li>

              <li className="nav--list__item">
                <LinkItem to="offering" offset={-50}>
                  <FormattedMessage {...messages.our_offering} />
                </LinkItem>
              </li>

              <li className="nav--list__item">
                <LinkItem to="contact" offset={-100}>
                  <FormattedMessage {...messages.contact_us} />
                </LinkItem>
              </li>
              <li className="nav--list__item">
                <a target="_blank" rel="noopener noreferrer" href="https://blog.evarfinance.com">
                  <FormattedMessage {...messages.blog} />
                </a>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
            </ul>
          </NavLinks>

          <AuthWrapper>
            <NavLink to="/join" className="active">
              <FormattedMessage {...messages.get_started} />
            </NavLink>
            <DarkModeToggle onClick={toggleTheme}>
              <Icon name={isDarkMode ? 'sun' : 'moon'} />
            </DarkModeToggle>
          </AuthWrapper>
        </NavContainer>
        <Hamburger aria-label="”Open the menu" show={show} onClick={() => setShow(!show)}>
          <span className="hamburger--bar" />
          <span className="hamburger--bar" />
        </Hamburger>
      </Container>
      <MobileMenu show={show} toggleShow={() => setShow(!show)} />
    </Fragment>
  );
};
