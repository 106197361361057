import { defineMessages } from 'react-intl';

const messages = defineMessages({
  verified_header: {
    id: 'join.verified_header',
    defaultMessage: 'Thank You!',
  },
  verified_sub_header: {
    id: 'join.sub_header',
    defaultMessage: `You'll be notified once we go live.`,
  },
  join_community: {
    id: 'join.community',
    defaultMessage: `Join our community.`,
  },
  return_home: {
    id: 'join.return_home',
    defaultMessage: `Return home`,
  },
  not_verified_header: {
    id: 'join.not_verified_header',
    defaultMessage: 'Great to have you!',
  },
  not_verified_sub_header: {
    id: 'join.not_verified_header_sub_header',
    defaultMessage: 'Please check your inbox or spam folder in your email to follow the next step',
  },
  back: {
    id: 'join.back',
    defaultMessage: 'Back',
  },
  join: {
    id: 'join.join',
    defaultMessage: 'Join',
  },
  not_joined_header: {
    id: 'join.not_joined_header',
    defaultMessage: 'Join the waiting list',
  },
  not_joined_sub_header: {
    id: 'join.not_joined_header_sub_header',
    defaultMessage: 'Be part of the emerging market',
  },
  full_name: {
    id: 'join.full_name',
    defaultMessage: 'Full name',
  },
  email_address_label: {
    id: 'join.email_address',
    defaultMessage: 'Email address',
  },
  email_address_placeholder: {
    id: 'join.email_addres_placeholder',
    defaultMessage: 'Enter a valid email address',
  },
});

export default messages;
