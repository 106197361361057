import { useTheme } from 'styled-components';
import { IconProps } from '../Icon.interface';

const Copy = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();
  return (
    <svg width={28} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.343 2H11.54c-1.424 0-2.583.993-2.583 2.214v.271H7.25c-1.423 0-2.582.993-2.582 2.214v13.087C4.667 21.008 5.826 22 7.249 22h9.803c1.424 0 2.583-.993 2.583-2.213v-.272h1.708c1.424 0 2.582-.993 2.582-2.214V4.214C23.925 2.993 22.767 2 21.343 2Zm-3.374 17.787c0 .433-.411.785-.917.785H7.25c-.505 0-.916-.352-.916-.785V6.699c0-.434.411-.786.916-.786h9.803c.506 0 .917.352.917.786v13.088Zm4.29-2.486c0 .434-.41.786-.916.786h-1.708V6.699c0-1.22-1.159-2.214-2.583-2.214h-6.429v-.272c0-.433.412-.785.917-.785h9.803c.505 0 .916.352.916.785v13.088Z"
        fill="#FF805C"
        stroke={colors[color || 'GRAY_COLOR']}
      />
    </svg>
  );
};

export default Copy;
