import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from '@app/components/atoms';
import { ActionModalProps } from './ActionModal.interface';

import messages from './messages';

import {
  Container,
  CTAButton,
  IconWrapper,
  ActionModalBody,
  ActionModalText,
  ActionModalButtonWrapper,
} from './ActionModal.styles';

export const ActionModal: React.FC<ActionModalProps> = ({
  show,
  type,
  message,
  onClose,
  disabled,
  isLoading,
  handleSubmit,
  ...props
}) => {
  switch (type) {
    case 'success': {
      return (
        <Container size="sm" centered show={show} {...props}>
          <Container.Body>
            <ActionModalBody>
              <IconWrapper>
                <Icon name="success" />
                <p>{message}</p>
              </IconWrapper>
              <ActionModalButtonWrapper>
                <Button onClick={onClose}>
                  <FormattedMessage {...messages.close} />
                </Button>
              </ActionModalButtonWrapper>
            </ActionModalBody>
          </Container.Body>
        </Container>
      );
    }
    case 'error': {
      return (
        <Container size="sm" centered show={show} {...props}>
          <Container.Body>
            <ActionModalBody>
              <IconWrapper>
                <Icon name="cancel" />
                <p>{message}</p>
              </IconWrapper>
              <ActionModalButtonWrapper>
                <Button onClick={onClose}>
                  {' '}
                  <FormattedMessage {...messages.try_again} />
                </Button>
              </ActionModalButtonWrapper>
            </ActionModalBody>
          </Container.Body>
        </Container>
      );
    }
    case 'confirm': {
      return (
        <Container size="sm" centered show={show} {...props}>
          <Container.Body>
            <ActionModalBody>
              <ActionModalText>{message}</ActionModalText>
              <ActionModalButtonWrapper>
                <CTAButton disabled={disabled} isLoading={isLoading} onClick={handleSubmit}>
                  <FormattedMessage {...messages.confirm} />
                </CTAButton>
                <Button onClick={onClose}>
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </ActionModalButtonWrapper>
            </ActionModalBody>
          </Container.Body>
        </Container>
      );
    }
  }
};
