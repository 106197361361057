import { useTheme } from 'styled-components';
import { IconProps } from '../Icon.interface';

const Growth = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();
  return (
    <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)" fill="#9A5E00">
        <path d="M2.397 9.9c.416.372.886.666 1.39.887L6.44 9.122a1.414 1.414 0 0 1 1.743.197l.892.892a5.326 5.326 0 0 0 2.175-4.274A5.318 5.318 0 0 0 5.937.625 5.318 5.318 0 0 0 .625 5.938 5.32 5.32 0 0 0 2.397 9.9Zm3.778-3.494H5.7a1.326 1.326 0 0 1-.231-2.632V3.28a.469.469 0 0 1 .937 0v.469h.625a.469.469 0 0 1 0 .938H5.7a.388.388 0 0 0-.388.387c0 .22.174.394.388.394h.475A1.326 1.326 0 0 1 6.406 8.1v.492a.469.469 0 0 1-.937 0v-.468h-.625a.469.469 0 0 1 0-.938h1.331a.388.388 0 0 0 .388-.387.39.39 0 0 0-.388-.394Z" />
        <path d="M14.375 7.5h-2.5a.625.625 0 0 0-.442 1.067l.808.808-2.553 2.554L7.629 9.87a.625.625 0 0 0-.77-.09L.296 13.844a.625.625 0 1 0 .658 1.062l6.141-3.802 2.15 2.15c.244.245.64.245.884 0l2.996-2.995.808.808A.625.625 0 0 0 15 10.625v-2.5a.625.625 0 0 0-.625-.625Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={colors[color || 'WHITE_COLOR']} d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Growth;
