import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  margin-top: 3rem;
  position: relative;
  padding: 5rem 4rem 5rem;
  font-family: ${({ theme }) => theme.fonts.RUBRIK};
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
  border-top: 2px solid ${({ theme }) => theme.colors.GHOST_WHITE_COLOR};

  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    &--address {
      gap: 2rem;
      display: flex;
      flex-direction: column;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
      margin-bottom: 0;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    &--text {
      @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 1.5rem;
      }
    }
  }

  p {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
  }

  .mail-text,
  .link-text {
    font-size: 1.7rem;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.BLACK_COLOR};
    font-family: ${({ theme }) => theme.fonts.RUBRIK};
  }

  .link-text {
    text-decoration: none;
    margin-right: 25px;
  }

  .dot {
    &::after {
          content: '';
          z-index: 1;
          width: 0.5rem;
          aspect-ratio: 1;
          position: absolute;
          margin-top: 0.5rem;
          border-radius: 50%;
          margin-left: 1.2rem;
          background-color: ${({ theme }) => theme.colors.BLACK_COLOR};
        }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.MOBILE}) {
    padding: 10rem 8vw 5rem;
  }

  ::before {
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 12.8rem;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/assets/svg/bottom-curve.svg');
  }
`;

export const LogoContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.JURA};
  .logo {
    &--text {
      font-size: 3rem;
      font-weight: bold;
      margin-left: -1.5rem;
    }

    &--icon {
      width: 7rem;
      height: auto;
      margin-left: -1.8rem;
    }
  }
`;

export const FooterList = styled.ul`
  display: flex;
  margin-top: 5rem;
  flex-direction: row;
  align-items: center;

  li:not(:last-child) {
    margin-right: 3rem;
  }
`;

export const AchorTag = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})`
  color: ${({ theme }) => theme.colors.BLACK_COLOR};
`;
