import { useTheme } from 'styled-components';
import { IconProps } from '../Icon.interface';

const Logo = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="8em"
      height="5em"
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      viewBox="0 0 5000 5000"
      {...props}
    >
      <path
        d="m4425.51 1679.36-552.11 86.43c-61.5 9.63-119.84-22.87-144-80.24-92.47-219.43-220.03-400.46-382.65-542.93-277.68-243.28-635.08-330.7-1072.16-262.28-302.88 47.41-552.95 156.28-750.19 326.61-197.24 170.31-336.02 381.56-416.34 633.73-37.56 117.91-61.21 240.94-70.99 369.1l-300.24 47 63.8 407.6 3364.04-526.59 260.82-458.41zm-1353.04 211.8-1310.69 205.17c20.92-145.11 69.26-267.11 145.05-365.95 104.39-136.14 256.26-219.82 455.64-251.03 207.04-32.41 374.94 5.14 503.72 112.63 91.11 76.07 159.88 175.81 206.27 299.18zm-273.42 2226.67c-306.72 48.01-580.36 25.05-820.93-68.88-240.56-93.93-438.88-250.44-594.94-469.56-48.33-67.86-91.69-140.89-129.87-218.93-26.15-53.5-82.08-82.54-140.87-73.34l-537.96 84.21 264.97-459.06 3364.04-526.59 63.8 407.59-2316.51 362.61c158.89 296.43 412.35 417.41 760.41 362.93 141.85-22.2 256.14-63.67 342.88-124.38 86.73-60.72 141.64-130.19 164.76-208.44l736.13-115.23c-32.65 268.28-151.03 496.94-355.15 686.03-204.11 189.07-471.03 309.41-800.76 361.02z"
        style={{ fill: colors[color || 'GRAY_COLOR'] }}
      />
    </svg>
  );
};

export default Logo;
