import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'notfound.title',
    defaultMessage: "Hmm... seems you're lost",
  },
  subtitle: {
    id: 'notfound.subtitle',
    defaultMessage: `That page can’t be reached, but if you double-check it, {br} we’re happy to try again.`,
  },
  go_back: {
    id: 'notfound.go_back',
    defaultMessage: '← Back to Homepage',
  },
});

export default messages;
