import { defineMessages } from 'react-intl';

const messages = defineMessages({
  about_evarfinance: {
    id: 'main_nav.about_evarfinance',
    defaultMessage: 'About Evarfinance',
  },
  our_offering: {
    id: 'main_nav.our_offering',
    defaultMessage: 'Our Offering',
  },
  contact_us: {
    id: 'main_nav.contact_us',
    defaultMessage: 'Contact Us',
  },
  get_started: {
    id: 'main_nav.get_started',
    defaultMessage: 'GET STARTED',
  },
  blog: {
    id: 'main_nav.blog',
    defaultMessage: 'Blog',
  },
});

export default messages;
