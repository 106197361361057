import { useTheme } from 'styled-components';
import { IconProps } from '../Icon.interface';

const Hamburger = ({ color, ...props }: IconProps) => {
  const { colors } = useTheme();
  return (
    <svg width={22} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.5 1.25a.75.75 0 0 1-.75.75H1.25a.75.75 0 0 1 0-1.5h19.5a.75.75 0 0 1 .75.75Zm-3.75 6H1.25a.75.75 0 0 0 0 1.5h16.5a.75.75 0 1 0 0-1.5ZM11 14H1.25a.75.75 0 1 0 0 1.5H11a.75.75 0 1 0 0-1.5Z"
        fill={colors[color || 'BLACK_COLOR']}
      />
    </svg>
  );
};

export default Hamburger;
