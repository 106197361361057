import { IconProps } from '../Icon.interface';

const FastWithdrawal = ({ color, ...props }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={152}
      height={152}
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'auto',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      viewBox="0 0 30480 30480"
      {...props}
    >
      <path
        d="M17812.58 17254.04c-15.89 262.56-115.49 350.79-292.5 523.69-400.76 391.45-827.11 825.92-1224.05 1224.52-135.75 136.3-272.34 271.77-408.29 407.89-146.57 146.76-256.87 289.66-462.97 354.7-211.44 66.73-446.79 12.22-611.41-135.6-136.22-122.33-278.19-275.83-409.08-407.23l-1224.34-1224.22c-117.16-117.77-309.46-288.21-403.35-413.52-277.43-370.3-83.92-898.74 365.09-1002.93 219.98-51.05 431.49 21.04 592.68 173.85 207.6 196.79 403.87 409.71 612.83 607.51 42.3 40.04 157.57 194.39 205.98 193.23 71.52-1.71 43.6-282.83 41.96-330.38-4.52-131.52.7-270.94.7-403.61.03-544.07-1.92-1088.47-.12-1632.5 1.49-449.92-91.42-1005.18 433.72-1198.89 213.06-78.59 446.45-31.32 619.46 113.78 178.92 150.05 230.46 356.42 235.35 581.01 17.33 796.27-6.65 1636.59 1.86 2445.47.96 90.62-22.05 329.99 9.57 399.5 39.92 87.79 162.73-87.92 191.13-119.86l405.91-404.03c116.28-118.11 290.55-314.9 439.16-377.67 390.67-165 913.96 75.87 880.71 625.29zm-7129.65-5937.37c-27.57 231.79-28.94 416.1-23.58 648.58 5.12 221.63-.42 449.26-.42 671.73v8097.87c0 223.67 1.75 447.72.86 671.29-.88 220.15-13.83 445.81 15.73 664.43 88.83 656.8 571.56 1262.22 1180.68 1515.94 444.17 185.01 729.56 156.34 1195.9 156.41l4722.22 2.37c214.36-.61 400.85-11.98 608.01-72.15 660.01-191.66 1154.02-680.69 1361.48-1335.14 113.4-357.71 78.66-876.93 77.64-1274.67-6.9-2696.84-.4-5400.89-.4-8098.28 0-224.59-1.67-449.25 1.65-673.81 1.64-110.28-8.09-218.68-7.67-328.4 1.61-426.85 147.27-696.59-342.37-665.88-119.63 7.51-232.77 19.71-353.38 17.31-114.7-2.29-222.25-8.04-337.91-6.16-448.82 7.3-903.43.55-1352.88.55h-5401.24c-446.82 0-898.28-8.42-1344.32 8.01z"
        style={{
          fill: '#796af9',
        }}
      />
      <path
        d="M9372.6 14583.39c15.83-67.53 6.03-1355.1 6.03-1458.3v-1640.38c.05-139.86 7.46-176.25-167.14-200.95-56.46-7.98-84.59-25.51-133.86-50.18-554.16-277.51-437.74-1155.64 294.17-1204.04 223.75-14.79 497.68-1.17 727-1.17h10266.37c233.61 0 506.85-13.05 735.47.7 733.18 44.09 855.35 914.64 313.2 1200-42.95 22.61-82.29 45.14-130.85 53.2-53.49 8.88-98.83 2.5-143.22 40.83-52.34 45.21-38.55 81.2-38.43 145.6v2752.64c0 83.09-9.97 293.8 6.04 362.05 58.04 27.24 483.84 15.69 579.78 15.58 193.19-.25 354.52-.52 543.99-48 297.38-74.54 550.64-203.53 788.88-395.4 216.47-174.34 400.75-412.22 523.12-661.15 166.28-338.26 202.83-618.86 200.23-992.05-5.54-792.2-1.43-1585.63-1.43-2377.97 0-394.44-3.32-790.23.2-1184.48 3.51-392.72-5.12-659.71-166.59-1025.69-238.13-539.8-709.05-938.75-1273-1103.55-349.2-102.04-750.09-71.78-1113.09-71.17-3564.49 5.93-7133.58.1-10698.58.1-394.69 0-789.41.46-1184.1-.03-367.8-.46-767.56-31.59-1121.89 69.92-565.13 161.93-1037.87 559.97-1278 1099.22-160.73 360.93-173.57 626.36-169.19 1015.09 4.47 396.63.31 795.67.31 1192.58 0 792.11 5.23 1586-1.34 2377.89-3.13 378.55 29.9 645.11 194.59 989.69 118.75 248.46 310.56 493.95 524.49 667.78 237.47 192.95 490.9 320.69 787.52 396.76 189.3 48.55 347.7 50.94 541.21 50.63 97.48-.16 529.33 11.83 588.11-15.75z"
        style={{
          fill: '#f77f65',
        }}
      />
    </svg>
  );
};

export default FastWithdrawal;
