import { defineMessages } from 'react-intl';

const messages = defineMessages({
  terms_of_use: {
    id: 'footer.terms_of_use',
    defaultMessage: 'Terms of Use',
  },
  privacy_policy: {
    id: 'footer.privacy_policy',
    defaultMessage: ' Privacy Policy',
  },
  rights_reserved: {
    id: 'footer.rights_reserved',
    defaultMessage: 'All Rights Reserved.',
  },
});

export default messages;
