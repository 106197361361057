import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { APP_URL_LIST } from '@app/routes/urls';
import messages from './messages';

import {
  Title,
  SubTitle,
  Container,
  GoBackButton,
  ContentWrapper,
  BackgroundCurve,
} from './NotFound.styles';

const NotFound: React.FC<{}> = () => {
  const navigate = useNavigate();

  const onBackClicked = () => navigate(APP_URL_LIST.HOME_URL, { replace: true });

  return (
    <Container>
      <ContentWrapper>
        <Title>
          <FormattedMessage {...messages.title} />
        </Title>

        <SubTitle>
          <FormattedMessage {...messages.subtitle} values={{ br: <br /> }} />
        </SubTitle>

        <GoBackButton onClick={onBackClicked}>
          <FormattedMessage {...messages.go_back} />
        </GoBackButton>
      </ContentWrapper>

      <BackgroundCurve />
    </Container>
  );
};

export default NotFound;
