import thunk from 'redux-thunk';
import { Middleware, createStore, compose, combineReducers, applyMiddleware } from 'redux';

import { session } from './session/reducers';
import { settings } from './settings/reducers';

const reducers = combineReducers({ session, settings });

const middlewares: Middleware[] = [thunk];

const composable = () => {
  const state = [applyMiddleware(...middlewares)];

  if (process.env.NODE_ENV === 'development') {
    const { name } = require('../../../package.json');
    const { reactotronRedux } = require('reactotron-redux');
    const Reactotron = require('reactotron-react-js').default;

    Reactotron.configure({ name }) // <- controls connection & communication settings
      .use(reactotronRedux()) // <- Listen to redux store updates
      .connect(); //  <- let's connect!

    // <- Extend app console
    console.tron = Reactotron.log;

    state.push(Reactotron.createEnhancer());
  }

  return state;
};

const store = createStore(reducers, compose(...composable()));

export { store };
