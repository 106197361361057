import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  email_required: {
    id: 'form.validator.email_required',
    defaultMessage: 'Email is required',
  },
  email_valid: {
    id: 'form.validator.email_valid',
    defaultMessage: 'Enter a valid email address',
  },
  full_name_required: {
    id: 'form.validator.full_name_required',
    defaultMessage: 'Full name is reqired',
  },
  name_length_valid: {
    id: 'form.validator.name_length_valid',
    defaultMessage: 'Field cannot be more than 255 characters',
  },
  min_password_length_valid: {
    id: 'form.validator.min_password_length_valid',
    defaultMessage: 'Password must be at least 6 characters long',
  },
  max_password_length_valid: {
    id: 'form.validator.max_password_length_valid',
    defaultMessage: 'Password must not be more than 20 characters long',
  },
  bussiness_name_required: {
    id: 'form.validator.bussiness_name_required',
    defaultMessage: 'Bussiness name is reqired',
  },
  password_required: {
    id: 'form.validator.password_required',
    defaultMessage: 'Password is reqired',
  },
  otp_valid: {
    id: 'form.validator.otp_valid',
    defaultMessage: 'Otp is reqired.',
  },
});

const useFormValidation = () => {
  const intl = useIntl();

  const emailValidation = {
    required: {
      value: true,
      message: intl.formatMessage(messages.email_required),
    },

    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
      message: intl.formatMessage(messages.email_valid),
    },
  };

  const businessEmailValidation = {
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
      message: intl.formatMessage(messages.email_valid),
    },
  };

  const loginPasswordValidation = {
    required: {
      value: true,
      message: intl.formatMessage(messages.password_required),
    },
  };

  const signupPasswordValidation = {
    ...loginPasswordValidation,
    minLength: {
      value: 6,
      message: intl.formatMessage(messages.min_password_length_valid),
    },
    maxLength: {
      value: 20,
      message: intl.formatMessage(messages.max_password_length_valid),
    },
  };

  const otpValidation = {
    required: {
      value: true,
      message: intl.formatMessage(messages.otp_valid),
    },
  };

  const fullNameValidation = {
    required: {
      value: true,
      message: intl.formatMessage(messages.full_name_required),
    },
    max: {
      value: 255,
      message: intl.formatMessage(messages.name_length_valid),
    },
  };

  const bussinessNameValidation = {
    required: {
      value: true,
      message: intl.formatMessage(messages.bussiness_name_required),
    },

    max: {
      value: 255,
      message: intl.formatMessage(messages.name_length_valid),
    },
  };

  return {
    otpValidation,
    emailValidation,
    fullNameValidation,
    loginPasswordValidation,
    bussinessNameValidation,
    businessEmailValidation,
    signupPasswordValidation,
  };
};

export default useFormValidation;
