import { IconProps } from '../Icon.interface';

const CancelMark = ({ color, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
      <g data-name="Group 44573" transform="translate(0 .383)">
        <circle
          data-name="Ellipse 2326"
          cx={15}
          cy={15}
          r={15}
          transform="translate(0 -.383)"
          fill="#ff805c"
          opacity={0.6}
        />
        <g data-name="Group 43510" fill="#fff" stroke="#fff" strokeWidth={0.6}>
          <g data-name="Rectangle 3367" transform="rotate(135 7.652 9.32)">
            <rect width={12.404} height={0.759} rx={0.38} stroke="none" />
            <rect x={-0.3} y={-0.3} width={13.004} height={1.359} rx={0.68} fill="none" />
          </g>
          <g data-name="Rectangle 3369" transform="rotate(45 -6.585 18.118)">
            <rect width={12.404} height={0.759} rx={0.38} stroke="none" />
            <rect x={-0.3} y={-0.3} width={13.004} height={1.359} rx={0.68} fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CancelMark;
