import Routes from '@app/routes';
import Providers from '@app/providers';
import { Toaster } from '@app/components/molecules';

export default function App() {
  return (
    <Providers>
      <Toaster />
      <Routes />
    </Providers>
  );
}
