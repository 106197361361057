/**
 * extending app global constants
 * @constant APP_URL_LIST default value
 */
export enum APP_URL_LIST {
  HOME_URL = '/',
  JOIN = '/join',
  FAQ = '/faq',
  NOT_FOUND_URL = '*',
}

export const PUBLIC_ROUTES = [
  APP_URL_LIST.JOIN,
  APP_URL_LIST.HOME_URL,
  APP_URL_LIST.NOT_FOUND_URL,
  APP_URL_LIST.FAQ,
];
