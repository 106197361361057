import { FC, Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, Controller, useForm } from 'react-hook-form';

import messages from './messages';
import { IStateTree } from 'typings/store';
import { Icon } from '@app/components/atoms';
import { useFormValidation } from '@app/hooks';
import { ActivityIndicator } from '@app/components/molecules';
import { IFormState, ISessionUser } from '@app/providers/store/session/models';
import {
  joinAwaitingListAction,
  verifyAwaitingListAction,
} from '@app/providers/store/session/actions';

import {
  Form,
  Input,
  Button,
  FormBg,
  SubText,
  FormTitle,
  AchorTag,
  Container,
  FormHeader,
  FormWrapper,
  IconWrapper,
  BottomCurve,
  CommunityText,
  TopLeftCurve,
  ReturnWrapper,
  SuccessHeader,
  InputWrapper,
  LogoContainer,
  FormContainer,
  TopRightCurve,
} from './Join.styles';

export const Join: FC<{}> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { emailValidation, fullNameValidation } = useFormValidation();
  const [formState, setformState] = useState<IFormState | null>(null);

  const { isLoading, data } = useSelector(({ session }: IStateTree) => session, shallowEqual);

  const verify_token = new URLSearchParams(window.location.search).get('verify_token');

  const showForm = !isLoading && !formState?.isVerified && !formState?.isSubmitted;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Pick<ISessionUser, 'email_address' | 'full_name'>>({
    defaultValues: { email_address: data?.user?.email_address },
  });

  const onSubmit: SubmitHandler<Pick<ISessionUser, 'email_address' | 'full_name'>> = (data) => {
    dispatch(
      joinAwaitingListAction(data, () =>
        setformState({ ...formState, isSubmitted: true } as IFormState)
      )
    );
  };

  useEffect(() => {
    if (verify_token) {
      dispatch(
        verifyAwaitingListAction(verify_token, () =>
          setformState({ ...formState, isVerified: true } as IFormState)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container>
        <TopRightCurve />
        <TopLeftCurve />
        <FormContainer>
          {!isLoading && (
            <ReturnWrapper onClick={() => navigate('/', { replace: true })}>
              <Icon name="arrow-left" color="PLACEHOLDER_GRAY" />
            </ReturnWrapper>
          )}

          {showForm ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormBg />

              <FormHeader>
                <LogoContainer>
                  <Icon name="logo" color="MEDIUMSLATEBLUE_COLOR" />
                </LogoContainer>

                <FormTitle>
                  <FormattedMessage {...messages.not_joined_header} />
                </FormTitle>
                <SubText>
                  <FormattedMessage {...messages.not_joined_sub_header} />
                </SubText>
              </FormHeader>

              <Controller
                control={control}
                name="full_name"
                rules={fullNameValidation}
                render={({ field }) => (
                  <InputWrapper>
                    <Input
                      type="text"
                      label={formatMessage(messages.full_name)}
                      {...field}
                      error={errors.full_name}
                      placeholder={formatMessage(messages.full_name)}
                    />
                  </InputWrapper>
                )}
              />

              <Controller
                control={control}
                name="email_address"
                rules={emailValidation}
                render={({ field }) => (
                  <Input
                    type="email"
                    label={formatMessage(messages.email_address_label)}
                    value={field.value}
                    onChange={field.onChange}
                    defaultValue={field.value}
                    error={errors.email_address}
                    placeholder={formatMessage(messages.email_address_placeholder)}
                  />
                )}
              />

              <Button isLoading={isLoading}>
                <FormattedMessage {...messages.join} />
              </Button>
            </Form>
          ) : null}

          {formState?.isVerified ? (
            <FormWrapper>
              <FormBg />

              <FormHeader>
                <LogoContainer>
                  <Icon name="logo" color="MEDIUMSLATEBLUE_COLOR" />
                </LogoContainer>
              </FormHeader>

              <SuccessHeader>
                <FormattedMessage {...messages.verified_header} />
              </SuccessHeader>
              <SubText>
                <FormattedMessage {...messages.verified_sub_header} />
              </SubText>
              <CommunityText>
                <FormattedMessage {...messages.join_community} />
              </CommunityText>

              <IconWrapper>
                <AchorTag href="https://t.me/evarfinance" marginRight={3}>
                  <Icon name="telegram" />
                </AchorTag>

                <AchorTag href="https://twitter.com/evarfinance" marginRight={3}>
                  <Icon name="twitter" />
                </AchorTag>

                <AchorTag href="https://www.instagram.com/evarfinance/" marginRight={3}>
                  <Icon name="instagram" />
                </AchorTag>

                <AchorTag href="https://www.linkedin.com/company/evarfinance/">
                  <Icon name="linkedin" />
                </AchorTag>
              </IconWrapper>

              <Button
                onClick={() => {
                  navigate('/');
                }}
              >
                <FormattedMessage {...messages.return_home} />
              </Button>
            </FormWrapper>
          ) : null}

          {formState?.isSubmitted ? (
            <FormWrapper>
              <FormBg />

              <FormHeader>
                <LogoContainer>
                  <Icon name="logo" color="MEDIUMSLATEBLUE_COLOR" />
                </LogoContainer>
              </FormHeader>

              <SuccessHeader>
                <FormattedMessage {...messages.not_verified_header} />
              </SuccessHeader>
              <SubText>
                <FormattedMessage {...messages.not_verified_sub_header} />
              </SubText>
            </FormWrapper>
          ) : null}
        </FormContainer>

        <BottomCurve />
      </Container>
      {isLoading && <ActivityIndicator isLoading={isLoading} />}
    </Fragment>
  );
};
