import { Dispatch } from 'redux';
import toast from 'react-hot-toast';
import { joinAwaitingList, verifyAwaitingList } from '@app/network';
import { SESSION_SUCCESS, SESSION_FAILURE, SESSION_REQUEST, INSERT_PARTIALS } from '../types';

import {
  ISetSession,
  ISetPartials,
  ISessionUser,
  IInsertPartial,
  ISessionFailure,
  ISessionStarted,
} from '../models';

export const sessionRequest = (): ISessionStarted => ({
  type: SESSION_REQUEST,
});

export const sessionSuccess = (session: ISessionUser): ISetSession => ({
  type: SESSION_SUCCESS,
  payload: session,
});

export const sessionFailure = (error: Error | null): ISessionFailure => ({
  type: SESSION_FAILURE,
  payload: error,
});

export const insertPartials = (user: IInsertPartial): ISetPartials => ({
  type: INSERT_PARTIALS,
  payload: { user },
});

export const joinAwaitingListAction = (
  payload: Pick<ISessionUser, 'email_address' | 'full_name'>,
  onSuccess: VoidFunction
) => {
  return async (dispatch: Dispatch) => {
    dispatch(sessionRequest());
    try {
      await joinAwaitingList(payload);
      dispatch(sessionRequest());
      onSuccess?.();
      toast.success("You're one step away!");
    } catch (err) {
      const error = err as Error;
      toast.error(error?.message);
      dispatch(sessionFailure(error));
    }
  };
};

export const verifyAwaitingListAction = (verify_token: string, onSuccess: VoidFunction) => {
  return async (dispatch: Dispatch) => {
    dispatch(sessionRequest());
    try {
      await verifyAwaitingList(verify_token);
      dispatch(sessionRequest());
      toast.success("You're verified!");
      onSuccess?.();
    } catch (err) {
      const error = err as Error;
      toast.error(error?.message);
      dispatch(sessionFailure(error));
    }
  };
};
