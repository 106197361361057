import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNavigate } from 'react-router-dom';
import { SubmitHandler, Controller, useForm } from 'react-hook-form';

import messages from './messages';
import { Icon } from '@app/components/atoms';
import { useFormValidation, usePost } from '@app/hooks';
import { ISessionUser } from '@app/providers/store/session/models';
import { ActionModal } from '@app/components/molecules';
import { insertPartials } from '@app/providers/store/session/actions';

import { PaymentForm } from '../PaymentForm';
import { CustomDropdown } from '../CustomDropdown';

import {
  Form,
  Input,
  Image,
  About,
  Button,
  Banner,
  Wrapper,
  Article,
  Section,
  Paragraph,
  CTAButton,
  ArticleText,
  GetStarted,
  IntroSection,
  ArticleCard,
  PaymentText,
  IntroHeader,
  AboutHeader,
  ImageGallery,
  SectionIntro,
  VerifySection,
  IntroContainer,
  SectionHeader,
  AboutContainer,
  ProductHeader,
  BannerSection,
  SectionSubText,
  ContactSection,
  PaymentSubText,
  GetStartedButton,
  TestimonialInput,
  AppointmentButton,
  TestimonialHeader,
  SectionHeaderContainer,
} from './Main.styles';

const option = [
  'Start using Evarfinance',
  'Enquire about Evarfinance',
  'Negotiate a plan for my business',
  'Discuss business opportunities',
];

const URL = 'https://sheet.best/api/sheets/6888ce55-0814-4251-99c0-26a83e995160';

export const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [open, setIsOpen] = useState(false);
  const { error, submitStatus, setData } = usePost(URL);
  const [selectedOption, setSelectedOption] = useState('');
  const { emailValidation, fullNameValidation } = useFormValidation();

  const {
    reset,
    control,
    formState,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<Pick<ISessionUser, 'email_address' | 'full_name'>>();

  const awaitingForm = useForm<Pick<ISessionUser, 'email_address'>>();

  const handleSignUp = () => navigate('/join');

  const onSubmit: SubmitHandler<Pick<ISessionUser, 'email_address'>> = (data) => {
    dispatch(insertPartials(data));
    handleSignUp();
  };

  const handleContactFormSubmit: SubmitHandler<
    Pick<ISessionUser, 'email_address' | 'full_name'>
  > = (data) => {
    setData({ ...data, appointment: selectedOption });
    setIsOpen(true);
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({
        full_name: '',
        email_address: '',
      });
    }
  }, [formState, isSubmitSuccessful, reset]);

  return (
    <Wrapper>
      <IntroSection>
        <IntroContainer>
          <div className="payment--container">
            <PaymentText>
              <FormattedMessage {...messages.payment_text} />
            </PaymentText>

            <PaymentSubText>
              <FormattedMessage {...messages.payment_sub_text} />
            </PaymentSubText>

            <Form onSubmit={awaitingForm.handleSubmit(onSubmit)}>
              <div className="form--input">
                <Controller
                  name="email_address"
                  control={awaitingForm.control}
                  rules={emailValidation}
                  render={({ field }) => (
                    <Input
                      onChange={field.onChange}
                      placeholder={formatMessage(messages.email_placeholder)}
                      error={awaitingForm.formState.errors.email_address}
                    />
                  )}
                />
              </div>

              <Button>
                <FormattedMessage {...messages.get_started} />
              </Button>
            </Form>
          </div>
          <div className="paymentform--wrapper">
            <PaymentForm />
          </div>
        </IntroContainer>
      </IntroSection>

      <AboutContainer id="about">
        <ImageGallery>
          <img
            src={process.env.PUBLIC_URL + '/images/Globe-Image.gif'}
            alt="gif"
            className="globe-image"
            width="539"
            height="512"
          />
        </ImageGallery>

        <About>
          <IntroHeader>
            <FormattedMessage {...messages.about_evarfinance} /> Evarfinance
          </IntroHeader>
          <AboutHeader>
            <FormattedMessage {...messages.about_title} />
          </AboutHeader>
          <Paragraph>
            <FormattedMessage {...messages.about_sub_title} />
          </Paragraph>
          <GetStarted>
            <GetStartedButton onClick={handleSignUp}>
              <FormattedMessage {...messages.get_started_now} />
            </GetStartedButton>
          </GetStarted>
        </About>
      </AboutContainer>

      <Section id="offering">
        <SectionHeaderContainer>
          <SectionIntro>
            <FormattedMessage {...messages.offering_title} />
          </SectionIntro>
          <SectionHeader>
            <FormattedMessage {...messages.offering_heading} />
          </SectionHeader>
          <SectionSubText>
            <FormattedMessage {...messages.offering_sub_heading} />
          </SectionSubText>
        </SectionHeaderContainer>
      </Section>
      <VerifySection>
        <div className="mt">
          <div className="side--product">
            <Icon name="send" />

            <ProductHeader>
              <FormattedMessage {...messages.instant_settlement} />
            </ProductHeader>
            <p className="product--text">
              <FormattedMessage {...messages.instant_settlement_subtext} />
            </p>
          </div>
          <div className="side--product">
            <Icon name="lowfees" />

            <ProductHeader>
              {' '}
              <FormattedMessage {...messages.transaction_fees} />
            </ProductHeader>
            <p className="product--text">
              <FormattedMessage {...messages.transaction_fees_subtext} />
            </p>
          </div>
        </div>
        <figure className="order">
          <picture>
            <source
              type="image/webp"
              srcSet="https://res.cloudinary.com/dn8vprize/image/upload/s--l_K4hxms--/q_auto/v1648852909/phone_cmrcaa.webp"
            />
            <Image
              alt="phone"
              src="https://res.cloudinary.com/dn8vprize/image/upload/v1664491050/phone-png_hueiwj.png"
            />
          </picture>
        </figure>
        <div className="mt">
          <div className="side--product">
            <Icon name="security" />
            <ProductHeader>
              <FormattedMessage {...messages.secure_transactions} />
            </ProductHeader>
            <p className="product--text">
              <FormattedMessage {...messages.secure_transactions_subtext} />
            </p>
          </div>
          <div className="side--product">
            <Icon name="fast-withdrawal" />
            <ProductHeader>
              <FormattedMessage {...messages.fast_withdrawal} />
            </ProductHeader>
            <p className="product--text">
              <FormattedMessage {...messages.fast_withdrawal_subtext} />
            </p>
          </div>
        </div>
      </VerifySection>

      <Article>
        <SectionHeader>
          <FormattedMessage {...messages.who_is_evarfinance_for} />
        </SectionHeader>
        <div className="flex">
          <ArticleCard>
            <h2>
              <FormattedMessage {...messages.for_creators} />
            </h2>
            <ArticleText>
              <FormattedMessage {...messages.for_creators_subtext} />
            </ArticleText>
            <ArticleText>
              <FormattedMessage {...messages.for_creators_article} />
            </ArticleText>
          </ArticleCard>

          <ArticleCard>
            <h2>
              <FormattedMessage {...messages.for_business} />
            </h2>
            <ArticleText>
              <FormattedMessage {...messages.for_business_subtext} />
            </ArticleText>
            <ArticleText>
              <FormattedMessage {...messages.for_business_article} />
            </ArticleText>
          </ArticleCard>

          <ArticleCard>
            <h2>
              <FormattedMessage {...messages.for_freelancers} />
            </h2>
            <ArticleText>
              <FormattedMessage {...messages.for_freelancers_subtext} />
            </ArticleText>
            <ArticleText>
              <FormattedMessage {...messages.for_freelancers_article} />
            </ArticleText>
          </ArticleCard>
        </div>
      </Article>

      <BannerSection>
        <Banner>
          <div className="banner--wrapper">
            <p className="banner-header">
              <FormattedMessage {...messages.refund_guarantee} />
            </p>

            <p className="banner-text">
              <FormattedMessage {...messages.refund_guarantee_article} />
            </p>
          </div>
          <CTAButton to="/join">
            <FormattedMessage {...messages.get_started_now} />
          </CTAButton>
        </Banner>
      </BannerSection>

      <ContactSection id="contact">
        <form onSubmit={handleSubmit(handleContactFormSubmit)}>
          <div className="contact-header">
            <IntroHeader>
              <FormattedMessage {...messages.get_in_touch} />
            </IntroHeader>
            <TestimonialHeader>
              <FormattedMessage {...messages.schedule_appointment} />
            </TestimonialHeader>
          </div>
          <div className="input--wrapper">
            <div className="input--wrapper__item">
              <p className="input--text">
                <FormattedMessage {...messages.my_name} />
              </p>

              <div className="contact--input">
                <Controller
                  name="full_name"
                  control={control}
                  rules={fullNameValidation}
                  render={({ field }) => (
                    <TestimonialInput
                      placeholder={formatMessage(messages.full_name_placeholder)}
                      type="text"
                      onChange={field.onChange}
                      error={errors.full_name}
                    />
                  )}
                />
              </div>
            </div>

            <div className="input--wrapper__item">
              <p className="input--text">
                <FormattedMessage {...messages.i_want_to} />
              </p>

              <CustomDropdown
                selectedValue={selectedOption}
                onChange={(val) => setSelectedOption(val)}
                options={option}
                placeholder="Select dropdown"
                className="dropdown"
              />
            </div>
          </div>

          <div className="input--control">
            <p className="input--text">
              <FormattedMessage {...messages.schedule_appointment_email_title} />
            </p>
            <div className="emailinput-container">
              <Controller
                name="email_address"
                control={control}
                rules={emailValidation}
                render={({ field }) => (
                  <TestimonialInput
                    placeholder={formatMessage(messages.schedule_appointment_email_placeholder)}
                    type="email"
                    onChange={field.onChange}
                    error={errors.email_address}
                  />
                )}
              />
            </div>
          </div>
          <GetStarted>
            <AppointmentButton>
              <FormattedMessage {...messages.schedule_appointment} />
            </AppointmentButton>
          </GetStarted>
        </form>
      </ContactSection>

      {error && (
        <ActionModal type="error" show={open} message={error} onClose={() => setIsOpen(false)} />
      )}

      {submitStatus && (
        <ActionModal
          type="success"
          message={submitStatus}
          show={open}
          onClose={() => setIsOpen(false)}
        />
      )}
    </Wrapper>
  );
};
