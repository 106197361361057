import { Fragment } from 'react';
import { Navigation } from './components/Navigation';
import { Footer } from './components/Footer';
import { Main } from './components/Main';

export const Home = () => {
  return (
    <Fragment>
      <Navigation />
      <Main />
      <Footer />
    </Fragment>
  );
};
