import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'faq.title',
    defaultMessage: 'Frequently Asked Questions',
  },
});

export default messages;
