import moment from "moment";
import { useEffect, useState, useCallback } from "react";

// DEFAULT COUNT DOWN TIMERS
const TIME_INTERVAL = 1000;

export default function useCountDown(
  timeToCount?: moment.DurationInputObject,
  timeInterval: number = TIME_INTERVAL
) {
  const [timeLeft, setTimeLeft] = useState("00:00");
  const [countDownCompleted, setCountDownCompleted] = useState(true);

  const startTimer = useCallback(
    (timeToCount?: moment.DurationInputObject, timeInterval: number = TIME_INTERVAL) => {
      if (!timeToCount) return;

      if (countDownCompleted) {
        setCountDownCompleted(false);
      }

      let interval: NodeJS.Timer;
      let duration = moment.duration(timeToCount, "milliseconds");

      interval = setInterval(() => {
        if (duration.asMilliseconds() <= 0) {
          setCountDownCompleted(true);
          return clearInterval(interval);
        }

        duration = moment.duration(duration.asMilliseconds() - timeInterval, "milliseconds");

        let minutes = duration.minutes().toString();
        let seconds = duration.seconds().toString();

        minutes = Number(minutes) < 10 ? `0${minutes}` : minutes;
        seconds = Number(seconds) < 10 ? `0${seconds}` : seconds;
        setTimeLeft(`${minutes}:${seconds}`);
      }, timeInterval);

      return interval;
    },
    [countDownCompleted]
  );

  useEffect(() => {
    const interval = startTimer(timeToCount, timeInterval);
    return () => interval && clearInterval(interval);
  }, [startTimer, timeInterval, timeToCount]);

  const restartTime = useCallback(
    (time: moment.DurationInputObject, interval: number = timeInterval) =>
      startTimer(time, interval),
    [startTimer, timeInterval]
  );

  return { timeLeft, restartTime, countDownCompleted } as const;
}
