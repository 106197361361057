import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Button } from '@app/components/atoms';

export const Container = styled(Modal)`
  .modal-body,
  .modal-content {
    border: none;
    background-color: ${({ theme }) => theme.colors.WHITE_COLOR};
  }

  .modal-content {
    padding: 3.4rem;
    min-height: 40rem;
    min-width: 40rem;
    border-radius: 1rem;
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ActionModalBody = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.LIGHTSLATEGRAY_COLOR};
`;

export const ActionModalText = styled.p`
  text-align: center;
  margin: 8rem 0 9rem;
`;

export const IconWrapper = styled.div`
  gap: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 10rem;
  justify-content: center;
  flex-direction: column;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    > :first-child {
      margin-bottom: 1.5rem;
    }
  }
`;

export const ActionModalButtonWrapper = styled.div`
  gap: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    > :first-child {
      margin-bottom: 1.5rem;
    }
  }
`;

export const CTAButton = styled(Button)`
  color: ${({ theme }) => theme.colors.GRAY_COLOR};
  background-color: ${({ theme }) => theme.colors.GHOST_WHITE_COLOR};
  :focus {
    color: ${({ theme }) => theme.colors.GRAY_COLOR};
  }
`;
