import { ISessionUser, ISessionState, SessionActionTypes } from '../models';
import { SESSION_SUCCESS, INSERT_PARTIALS, SESSION_FAILURE, SESSION_REQUEST } from '../types';

const initialState: ISessionState = {
  data: null,
  error: null,
  isLoading: false,
};

const sessionReducer = (
  state: ISessionState = initialState,
  action: SessionActionTypes
): ISessionState => {
  switch (action.type) {
    case SESSION_REQUEST:
      return { ...state, isLoading: !state.isLoading };

    case INSERT_PARTIALS:
      return {
        ...state,
        error: null,
        isLoading: false,
        data: {
          ...state?.data,
          ...action?.payload,
          user: { ...state?.data?.user, ...action?.payload?.user },
        } as ISessionUser,
      };

    case SESSION_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        data: action.payload,
      };

    case SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default sessionReducer;
