import React from 'react';
import { Icon } from '@app/components/atoms';

import { Container } from './ActivityIndicator.styles';

export const ActivityIndicator: React.FC<{ isLoading: boolean; className?: string }> = ({
  isLoading,
  className,
}) => (
  <Container className={className}>
    <Icon name={isLoading ? 'loading' : 'success'} />
  </Container>
);
