import { useEffect, useState } from 'react';
import { useCountDown } from '@app/hooks';
import { Icon } from '@app/components/atoms';

import {
  Card,
  Input,
  Button,
  Container,
  SpentCard,
  ExpiryText,
  FormHeader,
  WhiteButton,
  LogoContainer,
  WalletWrapper,
  BarcodeWrapper,
  WalletContainer,
  MetricsContainer,
  MetricsBottomWrapper,
} from './PaymentForm.styles';

const GENDER = ['men', 'women'];
const BASE_IMAGE_URL = 'https://randomuser.me/api/portraits/med';

export const PaymentForm = () => {
  const { timeLeft, restartTime, countDownCompleted } = useCountDown();
  const [imageArr, setImageArr] = useState<string[]>([]);

  useEffect(() => {
    restartTime({ minutes: 14 });

    const gender = GENDER[Math.floor(Math.random() * GENDER.length)];
    const images = Array.from<string>({ length: 3 }).map(
      () => `${BASE_IMAGE_URL}/${gender}/${Math.floor(Math.random() * 100)}.jpg`
    );

    setImageArr(images);
  }, [countDownCompleted, restartTime]);

  return (
    <Container>
      <div className="card--wrapper">
        <Card>
          <MetricsContainer>
            <SpentCard>
              <div>
                <p className="spent--text">Total Spent</p>
                <p className="amount--text">$38,000.00</p>
              </div>
              <div className="icon_holder">
                <Icon name="growth" />
              </div>
            </SpentCard>
          </MetricsContainer>
          <FormHeader>
            <LogoContainer>
              <Icon name="logo" color="MEDIUMSLATEBLUE_COLOR" />
            </LogoContainer>
            <p className="email--text">satoshi.natamoto@gmail.com</p>
          </FormHeader>
          <div className="text--wrapper">
            <ExpiryText>
              Kindly proceed to your wallet to complete the payment. This ID expires in{' '}
              <span className="timer">{timeLeft} mins</span>
            </ExpiryText>
          </div>

          <Input right_icon="copy" disabled defaultValue={'0x1aD91ee08f21bE3dE0BA2ba6918E71'} />
          <BarcodeWrapper>
            <p className="scan--text">Or Scan QR </p>
            <Icon name="barcode" />
          </BarcodeWrapper>
          <Button disabled>I have made payment</Button>
          <WhiteButton disabled>Cancel</WhiteButton>
        </Card>
        <MetricsBottomWrapper>
          <WalletContainer>
            <WalletWrapper>
              <div className="image--wrapper">
                <img
                  src="https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@d5c68edec1f5eaec59ac77ff2b48144679cebca1/svg/icon/usdt.svg"
                  alt=""
                  className="wallet--image"
                />

                <p className="wallet_name">Received USDT</p>
              </div>

              <p className="wallet_usage">+ $3000.50</p>
            </WalletWrapper>

            <WalletWrapper>
              <p className="date--text">Jan 18, 2022</p>
              <div className="hex-background">
                {imageArr.map((src, index) => (
                  <img src={src} key={index} alt="" className="hex--image" />
                ))}
                <span className="hex--shape">+8</span>
              </div>
            </WalletWrapper>
          </WalletContainer>
        </MetricsBottomWrapper>
      </div>
    </Container>
  );
};
