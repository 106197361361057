import { FunctionComponent } from 'react';

import ThemeProvider from './theme';
import StoreProvider from './store';
import IntlProvider from './internationalization';

const Providers: FunctionComponent<{}> = ({ children }) => {
  return (
    <StoreProvider>
      <IntlProvider>
        <ThemeProvider>{children}</ThemeProvider>
      </IntlProvider>
    </StoreProvider>
  );
};

export default Providers;
