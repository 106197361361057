import { FunctionComponent } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import * as store from './store';

const Provider: FunctionComponent<{}> = ({ children }) => (
  <ReduxProvider store={store.store}>{children}</ReduxProvider>
);

export default Provider;
