import styled, { css } from 'styled-components';
import { InputGroup, FormControl } from 'react-bootstrap';

export const Container = styled(InputGroup)<{ inputtype?: string; error?: boolean }>`
  display: flex;
  padding: 0rem 2rem;
  align-items: center;
  border-radius: 1rem;
  height: ${({ inputtype }) => (inputtype ? 10 : 6)}rem;
  background-color: ${({ theme }) => theme.colors.GHOST_WHITE_COLOR};

  .input_left_icon {
    margin-right: 0.68rem;
  }

  .input_right_icon {
    margin-left: 0.68rem;
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.hexToRGB(theme.colors.ERROR_COLOR, 0.9)} !important;
    `}

  svg {
    path {
      fill: ${({ theme }) =>
        theme.isDarkMode ? theme.colors.BLACK_COLOR : theme.colors.GRAY_COLOR};
    }
  }
`;

export const InputControl = styled(FormControl)`
  flex: 1;
  padding: 0px;
  height: 100%;
  border: none;
  resize: none;
  outline: none;
  font-weight: 500;
  font-size: 1.7rem;
  margin-bottom: 0.3rem;
  padding-top: ${({ as }) => (as ? 1.8 : 0)}rem;
  padding-bottom: ${({ as }) => (as ? 1.8 : 0)}rem;
  color: ${({ theme }) => theme.colors.GRAY_COLOR};
  background-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
  caret-color: ${({ theme }) =>
    theme.isDarkMode ? theme.colors.BLACK_COLOR : theme.colors.LIGHTSLATEGRAY_COLOR};

  ::placeholder {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.PLACEHOLDER_GRAY};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
    background-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
  }
`;

export const ErrorMessage = styled.span`
  display: block;
  font-size: 1.2rem;
  margin-top: 0.7rem;
  color: ${({ theme }) => theme.colors.RED_COLOR};
`;

export const Label = styled.label`
  color: #666b7a;
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 1rem;
`;

export const ShowText = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.LIGHTSLATEGRAY_COLOR};
`;

export const ShowButton = styled.span`
  display: block;
  margin-left: -5rem;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.TRANSPARENT_COLOR};
`;
