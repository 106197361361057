import styled from 'styled-components';

export const PrimaryButton = styled.button`
  width: 100%;
  color: #fff;
  height: 5.6rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.8rem;
  border-radius: 1rem;
  padding: 0.625rem 1.25rem;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.MEDIUMSLATEBLUE_COLOR};

  :focus {
    color: #fff;
  }

  :disabled {
    pointer-events: none;
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  width: 100%;
  color: ${({ theme }) => theme.colors.DARKSLATEBLUE_COLOR};
  background-color: ${({ theme }) => theme.colors.THISTLE_COLOR};
  border: 1px solid ${({ theme }) => theme.colors.WHITE_COLOR};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.WHITE_COLOR};
  }
`;

export const TertiaryButton = styled(PrimaryButton)`
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.WHITE_COLOR};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-right: 0.625rem;
  }
`;
