import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';

import messages from './messages';
import { Icon } from '@app/components/atoms';
import { MenuProps } from './MobileMenu.interface';

import { Container, NavLinks, LinkItem, AuthWrapper, DarkModeToggle } from './MobileMenu.styles';

export const MobileMenu = ({ show, toggleShow }: MenuProps) => {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <Container show={show}>
      <NavLinks>
        <LinkItem to="about" smooth={true} onClick={toggleShow}>
          <FormattedMessage {...messages.about_evarfinance} />
        </LinkItem>
        <LinkItem to="offering" smooth={true} onClick={toggleShow}>
          <FormattedMessage {...messages.our_offering} />
        </LinkItem>

        <LinkItem to="contact" smooth={true} onClick={toggleShow}>
          <FormattedMessage {...messages.contact_us} />
        </LinkItem>

        <AuthWrapper>
          <NavLink to="/join" className="active">
            <FormattedMessage {...messages.get_started} />
          </NavLink>
          <DarkModeToggle onClick={toggleTheme}>
            <Icon name={isDarkMode ? 'sun' : 'moon'} />
          </DarkModeToggle>
        </AuthWrapper>
      </NavLinks>
    </Container>
  );
};
