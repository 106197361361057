import { IconProps } from '../Icon.interface';

const Success = ({ color, ...props }: IconProps) => {
  return (
    <svg width={70} height={70} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M35 4.685A30.315 30.315 0 1 0 65.315 35 30.349 30.349 0 0 0 35.001 4.684Zm0 57.037A26.722 26.722 0 1 1 61.723 35 26.752 26.752 0 0 1 35 61.722Z"
        fill="#58A771"
        stroke="#58A771"
      />
      <path
        d="m49.292 23.825-.006.006L30.76 42.358 20.718 32.316a1.796 1.796 0 1 0-2.54 2.54l11.311 11.31a1.797 1.797 0 0 0 2.54 0l19.797-19.794.006-.007a1.796 1.796 0 0 0-2.54-2.54Z"
        fill="#58A771"
        stroke="#58A771"
      />
    </svg>
  );
};

export default Success;
