import Send from './assets/send';
import Sun from './assets/sun';
import Logo from './assets/logo';
import Copy from './assets/copy';
import Mark from './assets/mark';
import Moon from './assets/moon';
import Close from './assets/close';
import Growth from './assets/growth';
import Cancel from './assets/cancel';
import Success from './assets/success';
import Barcode from './assets/barcode';
import Loading from './assets/loading';
import Security from './assets/security';
import Telegram from './assets/telegram';
import Linkedin from './assets/linkedin';
import LowFees from './assets/lowfees';
import Twitter from './assets/twitter';
import Instagram from './assets/instagram';
import CancelMark from './assets/cancel-mark';
import Hamburger from './assets/hamburger';
import ArrowLeft from './assets/arrow-left';
import ArrowRight from './assets/arrow-right';
import FastWithdrawal from './assets/fast-withdrawal';
import { IconProps, IconType } from './Icon.interface';

export const Icon = ({ name, ...props }: IconProps & { name: IconType }) => {
  switch (name) {
    case 'logo': {
      return <Logo {...props} />;
    }

    case 'mark': {
      return <Mark {...props} />;
    }

    case 'growth': {
      return <Growth {...props} />;
    }
    case 'cancel-mark': {
      return <CancelMark {...props} />;
    }

    case 'fast-withdrawal': {
      return <FastWithdrawal {...props} />;
    }

    case 'linkedin': {
      return <Linkedin {...props} />;
    }

    case 'lowfees': {
      return <LowFees {...props} />;
    }

    case 'telegram': {
      return <Telegram {...props} />;
    }

    case 'security': {
      return <Security {...props} />;
    }

    case 'send': {
      return <Send {...props} />;
    }

    case 'barcode': {
      return <Barcode {...props} />;
    }

    case 'moon': {
      return <Moon {...props} />;
    }

    case 'sun': {
      return <Sun {...props} />;
    }

    case 'instagram': {
      return <Instagram {...props} />;
    }

    case 'twitter': {
      return <Twitter {...props} />;
    }

    case 'loading': {
      return <Loading {...props} />;
    }

    case 'hamburger': {
      return <Hamburger {...props} />;
    }

    case 'success': {
      return <Success {...props} />;
    }

    case 'cancel': {
      return <Cancel {...props} />;
    }

    case 'copy': {
      return <Copy {...props} />;
    }

    case 'arrow-right': {
      return <ArrowRight {...props} />;
    }

    case 'arrow-left': {
      return <ArrowLeft {...props} />;
    }

    case 'close': {
      return <Close {...props} />;
    }

    default:
      return null;
  }
};
